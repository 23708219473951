import './users.css';
import React, { Fragment,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap'


import { SCORE_TYPE_CHECKBOX } from '../../constant';
import { USER_ID_SET } from '../../redux/actionTypes';
import classnames from 'classnames';
import WorkoutLeaderboard from '../schedule/workoutLeaderboard';
import Prworkoutlist from '../prworkout/prworkoutlist';
import UserProgressCalendar from './userProgressCalendar';


const UserProgress = (props) => {

    const dispatch = useDispatch();


    //SELECTORS
    const trans = useSelector(({translation}) => translation.translationsData);
    const settingsData = useSelector(({settings}) => settings.settingsData);


    //VARIABLES
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    return (
      <Fragment>
      <Breadcrumb parent={trans.bottom_nav_progress} title={trans.bottom_nav_progress} />
      <Container fluid={true}>

      

        <Row>
          <Col >
            <UserProgressCalendar 
              progressUserId={userId}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Prworkoutlist
              progressUserId={userId}
            />
          </Col>
        </Row>

        <Row>
          <Col >
              <Card>
                    <CardHeader>
                      <h3 className="font-weight-bold">{trans.user_leaderboard_progress}</h3>
                    </CardHeader>

                    <CardBody>
                      
                    <div>
                      <Nav tabs>
                        {settingsData.scoretypes.map((item,index) => {
                          if (item.type != SCORE_TYPE_CHECKBOX){
                          return (<NavItem key={`score_type_${item.id}`}>
                            <NavLink
                              style={{cursor:'pointer'}}
                              className={classnames({ active: activeTab === String(index+ 1) })}
                              onClick={() => { toggle(String(index+ 1)); }}>
                              {item.name}
                            </NavLink>
                          </NavItem>)
                          }
                        })}
                        
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        {settingsData.scoretypes.map((item,index) => {
                            return  (<TabPane tabId={String(index+ 1)}>
                             <div key={`workout_progress_${item.id}`}>
                                 <br />
                                 { activeTab == String(index+1) ? <WorkoutLeaderboard scheduleWorkout={item} fromProgress={true} scoreType={item.type}/> : null }
                             </div>
                          </TabPane>)
                         })}
                       
                      </TabContent>
                    </div>
                    
                    </CardBody>

                </Card>   
          </Col>
        </Row>
       
  
      </Container>
    </Fragment>
    );
}

export default UserProgress;