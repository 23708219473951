import './prworkout.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button,Nav, NavItem, NavLink, TabContent, TabPane,Table } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import { getDoneSchedules } from '../../redux/doneschedule/action';

import { SCORE_TYPE_CALORIES, SCORE_TYPE_CHECKBOX, SCORE_TYPE_KGS_LBS, SCORE_TYPE_METERS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
import { USER_ID_SET } from '../../redux/actionTypes';
import classnames from 'classnames';
import WorkoutLeaderboard from '../schedule/workoutLeaderboard';
import WorkoutLeaderboardDetailsModal from '../schedule/workoutLeaderboardDetailsModal';
import { fetchPrworkouts } from '../../redux/prworkout/action';
import AddPrworkout from './addprworkout';
import PrworkoutHistory from './prworkoutHistory';


const Prworkoutlist = (props) => {

    const dispatch = useDispatch();

    const {progressUserId} = props;

    //SELECTORS
    const trans = useSelector(({translation}) => translation.translationsData);
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const prworkoutsData = useSelector(({prworkout}) => prworkout.prWorkoutsData);

    const [activeTab, setActiveTab] = useState('1');

    const [kgsList, setKgsList] = useState([]);
    const [metersList, setMetersList] = useState([]);
    const [repsList, setRepsList] = useState([]);
    const [caloriesList, setCaloriesList] = useState([]);
    const [timesList, setTimesList] = useState([]);
    const [checkboxList, setCheckboxList] = useState([]);
    const [prWorkoutList, setPrWorkoutList] = useState([]);


    const [addPrWorkoutOpen, setAddPrWorkoutOpen] = useState(false);
    const [selectedPrWorkout, setSelectedPrWorkout] = useState(null);
    const [scoreTypeTitle, setScoreTypeTitle] = useState("");
    
    const [historyPrWorkoutOpen, setHistoryPrWorkoutOpen] = useState(false);

    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));

    const toggle = (tab,item) => {
      if(activeTab !== tab) setActiveTab(tab);
      setScoreTypeTitle(item.name);
    }

    // useEffect(() => {
    //     getPrWorkouts();
    //  }, []);

     useEffect(() => {
         if (progressUserId != null && progressUserId != ""){
            getPrWorkouts();
         }
     }, [progressUserId]);
  
     const getPrWorkouts = () =>  {
        let postData = {userId: progressUserId};
        dispatch(fetchPrworkouts(postData));
     }

    useEffect(() => {
        if (prworkoutsData != null){
            var arr = [];
            prworkoutsData.prworkouts.forEach(prworkout => {
                arr.push(prworkout);
            });
            setPrWorkoutList(arr);
        }
    }, [prworkoutsData]);
 

    const handleAddClick = (e,item) => {
        e.preventDefault();
        setSelectedPrWorkout(item);
        setAddPrWorkoutOpen(true);
    }

    const handleHistoryClick = (e,item) => {
        e.preventDefault();
        setSelectedPrWorkout(item);
        setHistoryPrWorkoutOpen(true);
    }

    const onAddPrworkoutClose = () => {
        //setSelectedPrWorkout(null);
        setAddPrWorkoutOpen(false);
    }

    const onAddPrworkoutStored = () => {
        getPrWorkouts();
    }

    const onHistoryPrworkoutClose = () => {
        //setSelectedPrWorkout(null);
        setHistoryPrWorkoutOpen(false);
    }
  

   function renderPersonalRecordList(scoreTypeItem){

     

        var scoreType = scoreTypeItem.type;

        var items = [];
        prWorkoutList.forEach(element => {
            if (element.type == scoreType){
            items.push(element);
            }
        });

     
    
        return(<div key={`peronal_records_${scoreType}`}>
                <Table>
                <thead>
                <tr>
                    <th className="font-weight-bold">Workout</th>
                    <th className="font-weight-bold">{trans.best_score}</th>
                    <th className="font-weight-bold">{trans.latest_score}</th>
                    {userId == progressUserId ? <th></th> : null}
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {items.map((item,index) => {
                    
                    var bestValueText = "N/A";
                    var latestValueText = "N/A";
            
                    if (item.bestPr != null){
                        
                        bestValueText = <div><span style={{fontSize:'20',fontWeight:'600'}}>{item.bestPr.typeText}</span> • <span style={{fontSize:'13',fontWeight:'400',color:'grey'}}>{item.bestPr.readableDate}</span></div>;
                        //console.log(item);
                        // if (item.bestPr.scoreType == SCORE_TYPE_KGS_LBS){
                     
                        //     var customValue = "";
                        //     if (item.bestPr.customType == 'KGS'){
                        //         customValue = `${item.bestPr.kgs}Kgs`;
                        //     } else {
                        //         customValue = `${item.bestPr.lbs}lbs`;
                        //     }
                        //     bestValueText = `${customValue} • ${item.bestPr.readableDate}`;
                        // }
                    } 

                    if (item.latestpr != null){
                        latestValueText = <div><span style={{fontSize:'20',fontWeight:'600'}}>{item.latestpr.typeText}</span> • <span style={{fontSize:'13',fontWeight:'400',color:'grey'}}>{item.latestpr.readableDate}</span></div>;
                
                      
                        // if (item.latestpr.scoreType == SCORE_TYPE_KGS_LBS){
                     
                        //     var customValue = "";
                        //     if (item.latestpr.customType == 'KGS'){
                        //         customValue = `${item.latestpr.kgs}Kgs`;
                        //     } else {
                        //         customValue = `${item.latestpr.lbs}lbs`;
                        //     }
                        //     latestValueText = `${customValue} • ${item.latestpr.readableDate}`;
                        // }
                    } 

                    return (<tr key={`pr_workout_${item.id}`}>
                        <td>{item.name}</td>
                        <td>{bestValueText}</td>
                        <td>{latestValueText}</td>
                        {userId == progressUserId ? <td><Button onClick={(e) => {handleAddClick(e,item)}} color="secondary" size="sm"><i className="fa fa-plus"></i> {trans.add_btn}</Button></td> : null}
                        <td><Button onClick={(e) => {handleHistoryClick(e,item)}} color="primary" size="sm"><i className="fa fa-history"></i> {trans.btn_history}</Button></td>
                        <td></td>
                    </tr>)
                })}
                
                </tbody>
            </Table> 
          </div>)
    }


    return (<div>
         <Card>
            <CardHeader>
                <h3 className="font-weight-bold">{trans.progress_personal_record_title}</h3>
            </CardHeader>

            <CardBody>
                      {prworkoutsData == null ? <LoaderBox/> : 
                      <div>
                        <Nav tabs>
                          {settingsData.scoretypes.map((item,index) => {
                              if (item.type != SCORE_TYPE_CHECKBOX){
                                return (<NavItem key={`score_type_${item.id}`}>
                                <NavLink
                                    style={{cursor:'pointer'}}
                                    className={classnames({ active: activeTab === String(index+ 1) })}
                                    onClick={() => { toggle(String(index+ 1),item); }}>
                                    {item.name}
                                </NavLink>
                                </NavItem>)
                              }
                          })}
                          
                        </Nav>
                        <TabContent activeTab={activeTab}>
                          {settingsData.scoretypes.map((item,index) => {
                              return  (<TabPane tabId={String(index+ 1)} key={`tabworkout_${item.id}`}>
                               <div key={`workout_progress_${item.id}`}>
                                   <br />
                                   { activeTab == String(index+1) ? renderPersonalRecordList(item) : null }
                               </div>
                            </TabPane>)
                           })}
                         
                        </TabContent>
                      </div>
                      }
                      
             </CardBody>

         </Card>
 
       <AddPrworkout
        onAddPrworkoutClose={onAddPrworkoutClose}
        open={addPrWorkoutOpen}
        prWorkout={selectedPrWorkout}
        scoreTypeTitle={scoreTypeTitle}
        onAddPrworkoutStored={onAddPrworkoutStored}
       />

       <PrworkoutHistory 
        selectedPrWorkout={selectedPrWorkout}
        open={historyPrWorkoutOpen}
        scoreTypeTitle={scoreTypeTitle}
        onHistoryPrworkoutClose={onHistoryPrworkoutClose}
        onEditPrworkoutStored={onAddPrworkoutStored}
        progressUserId={progressUserId}
       />

    </div>)
   
}

export default Prworkoutlist;