import './schedule.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import React, { Fragment,useState,useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, FormGroup, Input, Label, CardBody,Button } from 'reactstrap'
import parse from 'html-react-parser';
import ReactHorizontalDatePicker from 'react-horizontal-strip-datepicker'
import 'react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css'
import * as General from '../../util/General'
import { Loader } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router';
import { addSchedule, getSchedules,deleteWorkout, editSchedule } from '../../redux/schedule/action';
import { getTrainings } from '../../redux/trainings/action';
import { getTracks } from '../../redux/tracks/action';
import WorkoutsPicker from '../workouts/workoutsPicker';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import SweetAlert from 'sweetalert2'
import AddWorkoutModal from '../workouts/addWorkoutModal';
import DoneSchedule from './doneSchedule';
import DatePicker from "react-datepicker";
import LoaderBox from '../../components/reuse/loaderbox';
import { checkUserSub } from '../../redux/subscription/action';
import NoAccess from '../../components/reuse/noaccess';
import WorkoutLeaderboardModal from './workoutLeaderboardModal';
import WorkoutNotesModal from './workoutNotesModal';
import ScheduleWorkout from './scheduleWorkout';
import { postEditTrack } from '../../redux/auth/action';


const DailyWorkoutList = (props) => {
  
  // GENERAL VARS
  let elRef = useRef(null);
  const dispatch = useDispatch();

  // SELECTORS
  const schedulesData = useSelector(({schedules}) => schedules.schedulesData);
  const trainingsResponse = useSelector(({trainings}) => trainings.trainingsData);
  const tracksData = useSelector(({tracks}) => tracks.tracksData);
  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const userIsManager = useSelector(({auth}) => auth.userIsManager);
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach);
  const userInfo = useSelector(({auth}) => auth.authUser);
  const checkSubData = useSelector(({subscription}) => subscription.checkSubData);
  const editUserSelectedTrackData = useSelector(({auth}) => auth.editUserSelectedTrackData);

  //STATE DATA
  const [curPage, setCurPage] = useState(0);
  const [selectedTraining, setSelectedTraining] = useState("");
  const [selectedTrack, setSelectedTrack] = useState("");
  const [scheduleEvents, setScheduleEvents] = useState([]);
  const [scheduleDoneEvents, setScheduleDoneEvents] = useState([]);
  const [doneScheduleToggle,setDoneScheduleToggle] = useState(false);
  const [doneScheduleWorkout,setDoneScheduleWorkout] = useState(null);
  const [doneScheduleEdit,setDoneScheduleEdit] = useState(false);
  const [userHasNoTrainings,setUserHasNoTrainings] = useState(false);
  const [workoutLeaderboardItem,setWorkoutLeaderboardItem] = useState(null);
  const [workoutLeaderboardOpen,setWorkoutLeaderboardOpen] = useState(false);

  const [workoutNotesModalOpen,setWorkoutNotesModalOpen] = useState(false);
  const [workoutNotesContentModal,setWorkoutNotesContentModal] = useState("");
  const [workoutNotesTitleModal,setWorkoutNotesTitleModal] = useState("");
  
  //VARIABLES
  var searchKeyword = "";
  var currentDate = new Date();
  var weekOfMonth = General.getISOWeekInMonth(currentDate);
  var month = General.getISOMonth(currentDate);
  var year = General.getISOYear(currentDate);
  var dayOfMonth = currentDate.getDate();
  var query = {'$or':[{month: month}],year:year,dayOfMonth};

  
  const [selectedDay, setSelectedDay] = useState(currentDate);

  const [trainingsArray, setTrainingsArray] = useState([]);

  const [userSelectedTraining, setUserSelectedTraining] = useState("");
  const [userSelectedTrack, setUserSelectedTrack] = useState("");

  useEffect(() => {
      reloadWithSelectedDay()
  }, [selectedDay]);

  const reloadWithSelectedDay = () => {
  //  console.log("reloading with track",trackId);
    currentDate = new Date(selectedDay);
    weekOfMonth = General.getISOWeekInMonth(currentDate);
    month = General.getISOMonth(currentDate);
    year = General.getISOYear(currentDate);
    dayOfMonth = currentDate.getDate();
    query = {'$or':[{month: month}],year:year,dayOfMonth};
     if (selectedTraining != "" && selectedTrack != ""){
      getSchedulesData(selectedTrack);
    }
  }
  
  //HOOKS
  useEffect(() => {
    setUserSelectedTraining(userInfo.selectedTraining);
    setUserSelectedTrack(userInfo.selectedTrack);

    getUserSubs();
  }, []);

  useEffect(() => {
    if (checkSubData != null){
      getTrainingssData();
    }
   
  }, [checkSubData]);

  const getUserSubs = () => {
    let postData = {}
    dispatch(checkUserSub(postData));
  }

  useEffect(() => {
    setScheduleEvents([]);
    if (schedulesData != null){
      var doneSchdedules = [];
      var todoSchedules = [];
      var todayDate = new Date();
      //LOOP TROUGHT THE DATA TO FIND THE WORKOUTS IN EACH SCHEDULE
      schedulesData.schedules.forEach(schedule => {
        var hasAccess = true;

        //ONLY ADMIN MANAGER OR COACH CAN SEE THE WHOLE SCHEDULE
        //USERS CAN ONLY SEE CURRENT AND PAST WEEKS

        // userInfo.roles.forEach(userRole => {
        //     if (userRole.slug == "super_admin" || 
        //     userRole.slug == "manager" ||
        //     userRole.slug == "coach") {
        //       hasAccess = true;
        //     }
        // });

        var scheduleDate = new Date()
        scheduleDate.setDate(schedule.dayOfMonth);
        scheduleDate.setMonth(schedule.month-1,schedule.dayOfMonth);
        scheduleDate.setFullYear(schedule.year);

       // console.log("DATES_DATA",`${todayDate.getMonth()} ${scheduleDate.getMonth()}`);
        // if (
        //   todayDate.getFullYear() == scheduleDate.getFullYear() &&
        //   todayDate.getMonth() == scheduleDate.getMonth() &&
        //   General.getISOWeekInMonth(scheduleDate) >= General.getISOWeekInMonth(todayDate)){
        //     if(!userIsAdmin && !userIsCoach && !userIsManager){
        //       hasAccess = false;
        //     }
        // }

        // console.log("scheduleDate",General.getISOWeekInMonth(scheduleDate));
        // console.log("todayDate",General.getISOWeekInMonth(todayDate));

        // // if(!userIsAdmin && !userIsCoach && !userIsManager){
        // //   if (
        // //     todayDate.getFullYear() == scheduleDate.getFullYear() &&
        // //     todayDate.getMonth() == scheduleDate.getMonth() &&
        // //     General.getISOWeekInMonth(scheduleDate) == General.getISOWeekInMonth(todayDate)){
             
        // //         hasAccess = false;
        // //   }
        // // }

        if (hasAccess){
          //NOW THAT WE HAVE EACH ITEM WE CAN LOOP THROUGH THE WORKOUTS
          schedule.workouts.forEach(scheduleWorkout => {
            //NOW WE WILL CREATE AN OBJECT FOR EACH WORKOUT
          //  console.log(scheduleWorkout);
            if (scheduleWorkout.doneSchedule != null){
              doneSchdedules.push(scheduleWorkout)
            } else {
              todoSchedules.push(scheduleWorkout);
            }
            
          });
       }
        
      });
      setScheduleEvents(todoSchedules);
      setScheduleDoneEvents(doneSchdedules)
    }
  }, [schedulesData]); 


  useEffect(() => {
    if (checkSubData != null && trainingsResponse != null && trainingsResponse.trainings.length > 0){
      var ar = [];
      trainingsResponse.trainings.forEach(training => {
        var hasAccess = false;
        if ( training.access_type == 2){
          hasAccess = true; //THE TRAINING IS FREE SO WE LET THE USER SEE IT
        } else {
          checkSubData.subscriptions.forEach(subscription => {
              if (subscription.planId == training.plan_id){ //LET THE USER SEE THIS ONLY IF HE HAS SUBSCRIPTION 
                hasAccess = true;
                
              }
          });
        }
        
        if (hasAccess || userIsAdmin || userIsCoach || userIsManager){
          ar.push(training);
        }

      });

      if (ar.length <= 0) {
        setUserHasNoTrainings(true);
       }

      setTrainingsArray(ar);

      if (selectedTraining == "" && ar.length > 0){ //THE FIRST TIME WE SET THE FIRST TRAINING AS DEFAULT
        setSelectedTraining(ar[0].id);
        if (userSelectedTraining != null && userSelectedTraining != "") {
          ar.forEach(element => {
            if (element.id == userSelectedTraining){
              setSelectedTraining(userSelectedTraining);
              //getTracksData(userSelectedTraining);
            }
          });
        } else {
          setSelectedTraining(ar[0].id);
          //getTracksData(ar[0].id);
        }

   
      } else {
        getTracksData(selectedTraining);
      }

    
    

    }
  }, [trainingsResponse]); 


  useEffect(() => {
   
    if (tracksData != null){
     //å console.log("tracksData",tracksData);
      if (tracksData.tracks.length > 0){
        if (selectedTrack == ""){
         // console.log("reloading schedule 1");
          if (userSelectedTrack != null && userSelectedTrack != "") {
            //console.log("reloading schedule 2");
            tracksData.tracks.forEach(element => {
              if (element.id == userSelectedTrack){
                setSelectedTrack(userSelectedTrack)
                //reloadWithSelectedDay(userSelectedTrack);
              }
            });
          } else {
            //console.log("reloading schedule 3");
            setSelectedTrack(tracksData.tracks[0].id)
            //reloadWithSelectedDay(tracksData.tracks[0].id);
          }
        
        } else {
          setSelectedTrack(tracksData.tracks[0].id)
          //console.log("reloading schedule 4");
          // reloadWithSelectedDay(selectedTrack);
        }
      }
    }


  }, [tracksData]); 

  

  useEffect(() => {
    if (selectedTraining != null){
      console.log("selectedTraining",selectedTraining);
      getTracksData(selectedTraining);
    }
  }, [selectedTraining]); 


  useEffect(() => {
    if (selectedTrack != null){
      console.log("selectedTrack",selectedTrack);
      reloadWithSelectedDay(selectedTrack);
    }
  }, [selectedTrack]); 


 
 
  //API CALLS
  const getSchedulesData = (track) => {
    query.trackId = track;
    let postData = {page: curPage+1,sort: {"_id": -1},query: query,searchKeyword: searchKeyword,checkDone: true}
    dispatch(getSchedules(postData));
  }
 
  const getTrainingssData = () => {
    let postData = {page: curPage+1,sort: {"_id": 1},query: {},searchKeyword: searchKeyword,fromApp:true}
    dispatch(getTrainings(postData));
  }

  const getTracksData = (training) => {
    if (training == "" || training == null){
      return;
    }
    let postData = {page: curPage+1,sort: {"_id": 1},query: {training_id: training},searchKeyword: searchKeyword,fromApp:true}
    dispatch(getTracks(postData));
  }


  //FUNCTIONS
  const handleTrainingChange = (e,item) => {
    setSelectedTraining(item);
    //setSelectedTrack("");
    //getTracksData(item)
  }

  const handleTrackChange = (e,item) => {
  //console.log("SELECTED_TRACK",item);
    setSelectedTrack(item);
   // reloadWithSelectedDay(item);
  }

  const onDoneScheduleClose = () => {
    setDoneScheduleWorkout(null)
    setDoneScheduleToggle(false);
  }

  const openDoneSchedule= (item) => {
   // console.log(item);
    setDoneScheduleEdit(false);
    setDoneScheduleWorkout(item)
    setDoneScheduleToggle(true);
  }

  const openEditDoneSchedule = (item) => {
    setDoneScheduleEdit(true);
    setDoneScheduleWorkout(item);
    setDoneScheduleToggle(true);

  }

  const doneScheduleStored = () => {
    onDoneScheduleClose();
    reloadWithSelectedDay(selectedTrack);
  }

  const onWorkoutLeaderboardModalClose = () => {
    setWorkoutLeaderboardItem(null)
    setWorkoutLeaderboardOpen(false);
  }

  const openLeaderBoards = (e,event) => {
    e.preventDefault();
    //OPEN TO SEE THE WORKOUT LEADERBOARD
    setWorkoutLeaderboardItem(event);
    setWorkoutLeaderboardOpen(true);
  }

  const openNotes = (e,notes,title) => {
    e.preventDefault();
  //  console.log(notes);
    setWorkoutNotesContentModal(notes);
    setWorkoutNotesModalOpen(true);
    setWorkoutNotesTitleModal(title);
  }



  function noDataView(text) {
    return(<div>
      <p className="_dayily_workout_no_data_text">{text}</p>
    </div>)
  }

  const selectedDayChanged = (val) =>{
    setSelectedDay(val)
  };

  const onWorkoutNotesModalClose = (e) => {
    e.preventDefault();
    setWorkoutNotesContentModal("");
    setWorkoutNotesModalOpen(false);
    setWorkoutNotesTitleModal("");
  }

  const selectUserTrack = (e) => {
    e.preventDefault();

    setUserSelectedTrack(selectedTrack);
    setUserSelectedTraining(selectedTraining);

    let postData = {
      selectedTrack: selectedTrack,
      selectedTraining: selectedTraining
    };
    dispatch(postEditTrack(postData));
  }

  return (
    <Fragment>
   
      <Container fluid={true}>

      <Row>
    

     
            <Col md="12">
              <Card style={{minHeight:'500px'}}>
                <CardHeader className="_dailyWorkoutHeader" >
                 <h4 className="font-weight-bold">{trans.daily_workout_title}</h4> 
                 
                </CardHeader>
                {checkSubData == null ? 
                  <LoaderBox/> : 
                  userHasNoTrainings  && (!userIsAdmin && !userIsManager && !userIsCoach) ?
                  <CardBody>
                    <div>
                      <NoAccess/>
                    </div>
                  </CardBody> :

                trainingsResponse != null && tracksData != null && schedulesData != null ? 

                
                <CardBody>
               
                
                <Row>


                <Col md='3'>
                <FormGroup>
                                  <Label className="font-weight-bold" for="exampleSelect">{trans.date_field}</Label>
                                  <div className="input-group" >
                                   <DatePicker  
                                        id="dashboardDatePicker"
                                        dateFormat="EEEE, d MMM yyyy "
                                        className="form-control digits" 
                                        selected={selectedDay} 
                                        disabledKeyboardNavigation={true}
                                        onChange={selectedDayChanged} />
                                </div>
                              </FormGroup>
                   
                  </Col> 

                <Col md='3'>
                    {trainingsResponse != null ?
                              <FormGroup>
                                  <Label className="font-weight-bold" for="exampleSelect">{trans.training_name}</Label>
                                  <Input className="custom-select" value={selectedTraining} onChange={(e)=> handleTrainingChange(e,e.target.value)} type="select" name="selectedTraining" id="selectedTraining">
                                      {trainingsArray.map((item,index) => {
                                           return(<option key={item.id} value={item.id}>{item.title}</option>)
                                      })}
                                  </Input>
                              </FormGroup>
                              : null}
                    </Col>

                 

                    <Col md='4'>
                    {tracksData != null ?
                              <FormGroup>
                                  <Label className="font-weight-bold" for="exampleSelect">{trans.track_name}</Label>
                                  <Input className="custom-select" value={selectedTrack} onChange={(e)=> handleTrackChange(e,e.target.value)} type="select" name="selectedTrack" id="selectedTrack">
                                      {tracksData.tracks.map((item,index) => {
                                         
                                       return(<option key={item.id} value={item.id}>{item.title}</option>)
                                          
                                      })}
                                  </Input>
                              </FormGroup>
                              : null}
                    </Col>

                    <Col md='2'>
                             <FormGroup>
                                  <Label className="font-weight-bold" for="exampleSelect">{trans.user_selected_program}</Label>
                                  <Button onClick={(e)=>{selectUserTrack(e)}} color={'white'} style={{borderColor:`${userSelectedTrack == selectedTrack ? 'grey' : 'red'}`,borderWidth:'1px',width:'100%'}}>{userSelectedTrack == selectedTrack ? trans.user_program_selected :  trans.user_program_submit}</Button>
                              </FormGroup>
                    </Col>


                </Row>

                <Row>

                   
               

                  <Col md={"6"}> 
                      <div>
                        <h4 style={{margin:'8px'}}>{trans.unfinished_workouts}</h4>
                          {scheduleEvents.length > 0 ? scheduleEvents.map((item,index) => {
                            // return renderEventContent(item,index);
                            return (<ScheduleWorkout
                                key={`ScheduleWorkout_${item.id}`}
                                event={item}
                                index={index}
                                openLeaderBoards={openLeaderBoards}
                                openNotes={openNotes}
                                openDoneSchedule={openDoneSchedule}
                                openEditDoneSchedule={openEditDoneSchedule}
                                fromProgress={false}
                                showLeaderboard={true}
                              />)
                          }) : noDataView(trans.no_workouts_todo)}
                      </div>
                  </Col>

                  <Col md={"6"}> 
                      <div>
                       <h4 style={{margin:'8px'}}>{trans.finished_workouts}</h4>
                       {scheduleDoneEvents.length > 0 ? scheduleDoneEvents.map((item,index) => {
                             return (<ScheduleWorkout
                              key={`ScheduleWorkout_${item.id}`}
                              event={item}
                              index={index}
                              openLeaderBoards={openLeaderBoards}
                              openNotes={openNotes}
                              openDoneSchedule={openDoneSchedule}
                              openEditDoneSchedule={openEditDoneSchedule}
                              fromProgress={false}
                              showLeaderboard={true}
                            />)
                          }): noDataView(trans.no_workouts_done)}
                      </div>
                  </Col>
              

                </Row>
                
                </CardBody>
                : <LoaderBox/>}

              </Card>
            </Col>
          
          </Row>
       
      </Container>

     <DoneSchedule
      isEdit={doneScheduleEdit}
      onStored={doneScheduleStored}
      scheduleWorkout={doneScheduleWorkout}
      onDoneScheduleClose={onDoneScheduleClose}
      open={doneScheduleToggle}
     />     

      <WorkoutLeaderboardModal
        open={workoutLeaderboardOpen}
        scheduleWorkout={workoutLeaderboardItem}
        onWorkoutLeaderboardClose={onWorkoutLeaderboardModalClose}
     />     

     <WorkoutNotesModal
        open={workoutNotesModalOpen}
        notes={workoutNotesContentModal}
        title={workoutNotesTitleModal}
        onWorkoutNotesModalClose={onWorkoutNotesModalClose}
     />

    </Fragment>
  );
}

export default DailyWorkoutList;