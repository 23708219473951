import React,{useState,useEffect} from 'react';
import {Container,Row,Col,Form,FormGroup,Label,Input,Button} from 'reactstrap' 
import { NewPassword,RetypePassword,Done, RememberPassword, CreateAccount} from "../../constant";
import {useDispatch, useSelector} from 'react-redux';
import {NotificationContainer, NotificationManager} from "react-notifications";

import {resetPass} from '../../redux/auth/action';

const Resetpwd = (props) => {

  var resetPassToken = "";
  if (props.match.params.resetPassToken){
    resetPassToken = props.match.params.resetPassToken;
  }

  const dispatch = useDispatch();
  const trans = useSelector(({translation}) => translation.storedTranslations);
  
  const changePasswordResponse = useSelector(({auth}) => auth.resetPass);

    const [togglePassword,setTogglePassword] = useState(false)
    const [password,setPassword] = useState("")
    const [rePassword, setRePassword] = useState('');

    useEffect(() => {
      if (changePasswordResponse){
        if (changePasswordResponse.status){
          props.history.push('/');
        } else {
          NotificationManager.listNotify = [];
          NotificationManager.error(changePasswordResponse.message);
        }
      }
  }, [changePasswordResponse]);

    const handleChange = (e) => {
      setPassword(e.target.value)
    }
    const handleReChange = (e) => {
      setRePassword(e.target.value)
    }

    const HideShowPassword  = (tPassword) => {
      setTogglePassword(!tPassword)
    }

    

    const sendResetPass = (e) => {
      e.preventDefault();
      if (password != ""){
        let postData = {token: resetPassToken ,newPassword: password, verifyPassword: rePassword};
        dispatch(resetPass(postData));
      }
    }

    return (
      <Container fluid={true}>
      <Row>
          <Col xs="12">     
            <div className="login-card">
              <div>
                <div><a className="logo" href="#javascript"><img className="img-fluid for-light" src={require("../../assets/images/logo/login.png")} alt="looginpage"/><img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="looginpage"/></a></div>
                <div className="login-main"> 
                  <Form className="theme-form">
                    <h4>{trans.web_change_password_title}</h4>
                    <p>{trans.web_change_password_info}</p>
                    <FormGroup>
                      <Label className="col-form-label">{trans.web_change_password_pass_one_hint}</Label>
                      <Input className="form-control" type={togglePassword ?  "text" : "password" } name="login[password]" value={password} onChange={(e) => handleChange(e)} required=""/>                   
                      <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span >{togglePassword ? trans.pass_hide : trans.pass_view}</span></div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{trans.web_change_password_pass_two_hint}</Label>
                      <Input className="form-control" type="password" name="login[password]" value={rePassword} onChange={(e) => handleReChange(e)} required="" />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      {/* <div className="checkbox ml-3">
                        <Input id="checkbox1" type="checkbox"/>
                        <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                      </div> */}
                      <br/>
                      <Button onClick={(e) => sendResetPass(e)} color="primary" className="btn-block" type="submit">{trans.submit_btn}</Button>
                    </FormGroup>
                    {/* <p className="mt-4 mb-0">{"Don't have account?"}<a className="ml-2" href="#javascript">{CreateAccount}</a></p> */}
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <NotificationContainer/>
      </Container>
    );
}

export default Resetpwd;