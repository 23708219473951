import './chats.css';
import classnames from 'classnames';
import parse from 'html-react-parser';
import React, { Fragment, useEffect, useRef, useState } from "react";
import Moment from 'react-moment';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import {
  Button, Card,
  CardBody, Col, Container, Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon, Media, Nav,
  NavItem,
  NavLink, Pagination, PaginationItem, PaginationLink, Row
} from "reactstrap";
import openSocket from 'socket.io-client';
import LoaderBox from '../../components/reuse/loaderbox';
// import {CALL,STATUS,PROFILE,VincentPorter,Active,ChatRoomaApp_p1,ChatRoomaApp_p2,Following,Follower,MarkJecno,Send} from '../../../constant'
import {
  DATE_TIME_FORMAT, Send, SOCKET_URL
} from "../../constant";
// import Breadcrumb from '../../../layout/breadcrumb'
import Breadcrumb from "../../layout/breadcrumb";
import {
  CHAT_CREATE_ROOM
} from "../../redux/actionTypes";
import { addRemoveChatRoom, createChatRoom, fetchChatMessages, fetchChatRoomsList, postChatMessage } from '../../redux/chat/action';
import { getAdminUsers, getCoachesData } from "../../redux/user/action";
import * as General from '../../util/General';
import './chats.css';
import ChatScreen from './chatScreen';

const ChatRoom = (props) => {
  
  const socket = openSocket(SOCKET_URL);

  const userLocale = General.getLocale();

  const history = useHistory();
  const dispatch = useDispatch();

  //LETS GRAB THE PASSED ROOM_ID
  var chatRoomPassedId = "";
  let { id } = useParams();
  if (id != null){
      chatRoomPassedId = id;
  }

  useEffect(() => {
    selectUserFromPassed();
   }, [chatRoomPassedId]);

  //SELECTOR
  const coachesData = useSelector(({ user }) => user.coachesData);
  const usersListData = useSelector(({ user }) => user.usersListData);
  const userInfo = useSelector(({ auth }) => auth.authUser);
  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
  const createChatRoomData = useSelector(({chat}) => chat.createChatRoomData);  
//  const chatMessagesData = useSelector(({chat}) => chat.chatMessagesData);  
  const chatRoomsListData = useSelector(({chat}) => chat.chatRoomsListData);  

  const chatRooms = null;
  

  //const currentUser = useSelector(content => content.ChatRoomApp.currentUser);
  // const online = useSelector(content => content.ChatRoomApp.online);
  //const members = useSelector(content => content.ChatRoomApp.members);
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [messageInput, setMessageInput] = useState("");
  // const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  // const [menuToggle, setMenuToggle] = useState(false);


  //var images = require.context('../../../assets/images', true);
  var images = [];

  //STATS
  const [selectedUser, setSelectedUser] = useState(null);
  const [curPage, setCurPage] = useState(0);
  const [usersPages, setUsersPages] = useState(0);
  const [chatUsers, setChatUsers] = useState([]);
  const [seletedChatRoomId,setSeletedChatRoomId] = useState(null);
 
  const [chatMessages, setChatMessages] = useState([]);

  var curChatMessagesPage = 1;
  let isLoadingPage = false;



  const [activeUserTab, setActiveUserTab] = useState(1);
  const toggleUserTabs = tab => {
    if(activeUserTab !== tab) setActiveUserTab(tab);
  }
  

  //  const chatScroll = useRef(null);
  //  const scrollToBottom = () => {
  //     if (chatScroll != null && chatScroll.current  != null){ 
  //     //chatScroll.current.scrollIntoView({ behavior: "smooth" });
  //       animateScroll.scrollToBottom({
  //         containerId: "chatMessagesContainer"
  //       });
  //     }
  //   };
  //   useEffect(scrollToBottom, [chatScroll,chatMessages]);


    useEffect(() => {
     // fetchCoaches();
       //COMPONENT CREATE HERE
  
      return () => {
        //COMPONENT DIE HERE LETS TELL THE API THAT WE ARE OUT OF THE ROOM
        addRemoveFromChatRoom(false);
        socket.disconnect()
      };
    }, []);


    const addRemoveFromChatRoom = (addRemove) => {
        let postData = {
            "toAdd": addRemove,
            "roomId": seletedChatRoomId,
        }
        dispatch(addRemoveChatRoom(postData));
    }
    


    //CHAT FUNCTIONS
    const subscribeToRoom = () => {
      if (seletedChatRoomId != null){
        const roomID = seletedChatRoomId
        const roomToSub = `room_${roomID}`
       // console.log(roomToSub);
        //console.log("socket",socket);

        // socket.on(roomToSub, (message) => {
        //   onNewMessage(message)
        // });

        addRemoveFromChatRoom(true);
      
        
      }
    }  

    const getChatRoomId = () => {
      //LETS CREATE OR FETCH THE CHATROOM BETWEEN TWO USERS
      // console.log("userInfo",userInfo);
      // console.log("selectedUser",selectedUser);
      let postData = {users:[userInfo.userId,selectedUser.userId]}
      dispatch(createChatRoom(postData));
    }

    useEffect(() => {
      //FIRST TIME THE COMPONENT LOADS IT WILL HAVE THE ACTIVE TAB 3 AS DEFAULT SO IT WILL GET THE CHAT ROOM HISTORY AND CREATEA ROOM WITH THE FIRST USER
      if (activeUserTab == 1){ 
      
          setChatUsers([]);
          setCurPage(0);
          fetchChatRooms();
      } else if (activeUserTab == 2) {
          setChatUsers([]);
          setCurPage(0);
          fetchCoaches();
      }  else   {
        setChatUsers([]);
        setCurPage(0);
        fetchUsersData();
    }
  }, [activeUserTab]);

  useEffect(() => {
    if (chatRoomsListData != null){
      var ar = [];
      chatRoomsListData.rooms.forEach(element => {
              ar.push(element);
      });

      //HOLD THE CHAT HISTORY AND PAGES NUM
      setChatUsers(ar);
      setUsersPages(chatRoomsListData.pages);

      //IF WE DONT HAVE A SELECTED USER THEN WE NEED TO SELECT THE FIRST ONE AVAILABLE IF ANY
      if (selectedUser == null){
        if (chatRoomPassedId == ""){
          if (ar.length > 0){
            setSelectedUser(ar[0]);
        }
        } else {
          selectUserFromPassed();
        }
      }
    }
  }, [chatRoomsListData]);

  const selectUserFromPassed = () => {
    chatUsers.forEach(element => {
      if (element.id == chatRoomPassedId){
        setSelectedUser(element);
      }
   });
  }

    // const onNewMessage = (message) => {
    //   //console.log("ney message",message);
    //   // this.items.push(<FadeDiv className="_chat_message" key={`chat_message_${message.id}`}>
    //   //     {this.renderChatMessage(message)}
    //   //   </FadeDiv>
    //   // );
    //   // this.setState({});
    //  // setChatMessages([...chatMessages, message]);

    //   setChatMessages(chatMessages => [...chatMessages, message]);
    // }

    useEffect(() => {
      if (selectedUser != null){
        //console.log("SELECTED+USER",selectedUser);
        //getChatRoomId()
      }
    }, [selectedUser]);

    useEffect(() => {
      if (seletedChatRoomId != null){
       // getChatMessages(false);
        subscribeToRoom();
      }
    }, [seletedChatRoomId]);


    // useEffect(() => {
    //   console.log("chatItemsScroll",chatItemsScroll);
    //   if (chatItemsScroll != null && chatItemsScroll.current != null){
    //     // if (chatMessagesData != null && chatMessagesData.cur_page == 1){
    //       // const scrollHeight = chatItemsScroll.current.scrollHeight;
    //       // const height = chatItemsScroll.current.clientHeight;
    //       // const maxScrollTop = scrollHeight - height;
    //       //chatItemsScroll.current.scrollIntoView({ behavior: "smooth" });
    //       console.log("SCROLLING TO BOTTOM");
    //       chatItemsScroll.current.scrollTop = chatItemsScroll.current.scrollHeight;
    //     // }
    //   }
    // }, [chatItemsScroll]);

    // useEffect(() => {
    //   if (chatScroll != null && chatScroll.current != null){
    //     console.log( chatScroll.current.scrollHeight);
    //     chatScroll.current.scrollIntoView();
    //   }
      
    // }, [chatScroll,chatScroll.current]);
    
  


    // useEffect(() => {
    //   //console.log("chat scroll did");
    // }, [chatScroll]);
  

//     useEffect(() => {
//       if (chatMessagesData != null){
//         isLoadingPage = false;
//         if (chatMessagesData.cur_page == 1){
          
//             setChatMessages(chatMessagesData.messages)
                
         
//             if (chatScroll != null && chatScroll.current != null){
//             //ADD A LISTENER TO LISTEN WHEN THE USER SCROLLS ON TOP
//             chatScroll.current.addEventListener("scroll", () => {
//             //  console.log("IS_SCROOLLING_",chatScroll.current.scrollTop);
//               if (chatScroll && chatScroll.current.scrollTop <= 10) {
//                   getChatMessages(true);
//               }
//           });
// //chatScroll.current.scrollTop = chatScroll.current.scrollHeight;
//             if (chatScroll.current) {
//               window.scrollTo({
//                 behavior: "smooth",
//                 top: chatScroll.current.offsetTop
//               });
//             }
//             }
     
//         } else {

//          // setChatMessages(chatMessages => [...chatMessages, chatMessagesData.messages]);
//           // const addMessage = (newMessage) => setMessages(state => [newMessage, ...state])

//           // console.log(chatMessagesData.messages);
//           // var arMessages = [];

//           chatMessagesData.messages.forEach(element => {
//             setChatMessages(chatMessages => [element, ...chatMessages]);
//           });

//           // chatMessagesData.messages.forEach(chatMessage => {
//           //   arMessages.unshift(chatMessage);
//           // });
          

//           // setChatMessages(arMessages);
//           // chatMessagesData.messages

         
//         }
//       }
//     }, [chatMessagesData]);

   

    


   

    useEffect(() => {
      if (createChatRoomData != null){
        //NOW THAT WE HAVE THE ROOM WE NEED TO FETCH THE MESSAGES THAT ARE ASSOCIATED WITH THIS ROOM ID
        setSeletedChatRoomId(createChatRoomData.room_id);
        dispatch({type: CHAT_CREATE_ROOM, payload: null});
      }
    }, [createChatRoomData]);
  
  
    // const getChatMessages = (toLoadMore) => {
    //   if (toLoadMore){
    //     // console.log(('I DID isLoadingPage',isLoadingPage));
    //     // console.log(chatMessages.length);
    //     if (isLoadingPage){
    //       isLoadingPage = false;
    //       return;
    //     }

    //     // console.log("TOTAL",`${chatMessagesData.total} == ${chatMessages.length}`);
    //     if (chatMessagesData.pages == curChatMessagesPage){
    //       isLoadingPage = false;
    //       return
    //     }
    //     curChatMessagesPage = curChatMessagesPage + 1;
    //   }

    //   isLoadingPage = true;

    //   let postData = {roomId: seletedChatRoomId, page: curChatMessagesPage}
    //   dispatch(fetchChatMessages(postData));
    // }
  
    // const sendChatMessage = () => {
    //   let postData = {roomId: seletedChatRoomId, message: messageInput}
    //   dispatch(postChatMessage(postData));
    //   setMessageInput("");
    // }
    //END OF CHAT FUNCTIONS

 

  const fetchCoaches = () => {
    let postData = {
        page: curPage + 1,
        sort: { _id: -1 },
        query: {},
        searchKeyword: searchKeyword,
        fromChat: true
      };
      dispatch(getCoachesData(postData));
  }


  const fetchChatRooms = () => {
    let postData = {
      page: curPage + 1,
      sort: { _id: -1 },
      query: {},
      searchKeyword: searchKeyword,
      fromChat: true
    };
    dispatch(fetchChatRoomsList(postData));
  }



  const fetchUsersData = () => {
    let postData = {
      page: curPage + 1,
      sort: { _id: -1 },
      query: {},
      searchKeyword: searchKeyword,
      fromChat: true
    };
    dispatch(getAdminUsers(postData));
  };


  useEffect(() => {
      if (coachesData != null){
        var ar = [];
        coachesData.coaches.forEach(element => {
                ar.push(element);
        });
        setChatUsers(ar);
        setUsersPages(coachesData.pages);

        if (selectedUser == null){
            if (ar.length > 0){
                setSelectedUser(ar[0]);
            }
        }
      }
  }, [coachesData]);

  useEffect(() => {
    if (usersListData != null){
      var ar = [];
      usersListData.data.forEach(element => {
              ar.push(element);
      });
      setChatUsers(ar);
      setUsersPages(usersListData.pages);

      if (selectedUser == null){
        if (ar.length > 0){
            setSelectedUser(ar[0]);
        }
    }
    }
  }, [usersListData]);



  // const dynamicImage = (image) => {
  //     return images(`./${image}`);
  // }

  // const toggleEmojiPicker = () => {
  //     setShowEmojiPicker(!showEmojiPicker);
  // }

  // const addEmoji = (emoji) =>  {
  //     const text = `${messageInput}${emoji.native}`;
  //     setShowEmojiPicker(false);
  //     setMessageInput(text)
  // }

  const changeChatRoomClick = (e, selectedUser) => {
    // if (searchKeyword != ""){
    //   handleSearchKeyword("");
    // }

    setSelectedUser(selectedUser)
    // const currentUserId = currentUser.id
    // const currentChatRoom = chats.find(x => x.users.includes(currentUser.id) && x.users.includes(selectedUserId))
    // if (currentChatRoom) {
    //   // dispatch(changeChatRoom(selectedUserId));
    // } else {
    //     dispatch({type : CREATE_CHAT_WATCHER , currentUserId, selectedUserId, chats})
    // }
  };

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    if (activeUserTab == 1){
        fetchCoaches();
    } else {
        fetchUsersData();
    }
    //dispatch(searchMember(keyword))
  };

  const handlePageClick = (e,newPage) => {
    e.preventDefault();
    if (activeUserTab == 1){
        setCurPage(newPage)
    } else {
        setCurPage(newPage)
    }
  }


  // const handleMessageChange = (message) => {
  //     setMessageInput(message)
  // }

  // const handleMessagePress = (e) => {
  //     if (e.key === "Enter" || e === "send") {

  //       if (messageInput != null && messageInput != ""){
  //         sendChatMessage();
  //       }

  //       //   var container = document.querySelector(".chat-history");
  //       //   setTimeout(function () {
  //       //       container.scrollBy({ top: 200, behavior: 'smooth' });
  //       //   }, 310)

  //       //   let currentUserId = currentUser.id;
  //       //   let selectedUserId = selectedUser.id;
  //       //   let selectedUserName = selectedUser.name;

  //       //   if (messageInput.length > 0) {
  //       //       dispatch({type : SEND_MESSAGE_WATCHER, currentUserId, selectedUserId, messageInput, chats, online})
  //       //       setMessageInput('');
  //       //       setTimeout(() => {
  //       //           const replyMessage = "Hey This is " + selectedUserName + ", Sorry I busy right now, I will text you later";
  //       //           if (selectedUser.online === true)
  //       //               document.querySelector(".status-circle").classList.add('online');
  //       //               selectedUser.online = true;
  //       //               dispatch({type : REPLY_MESSAGE_WATCHER,currentUserId, selectedUserId, replyMessage, chats, online})
  //       //       }, 5000);
  //       //   }
  //     }
  // }

  // const chatMenuToggle = () => {
  //     setMenuToggle(!menuToggle)
  // }

  // const selectedChatRoom = (allMembers && chats && selectedUser) ?
  // chats.find(x => x.users.includes(currentUser.id) && x.users.includes(selectedUser.id)) :
  // null;

  // var activeChatRoom = 0;
  // if (selectedUser != null)
  //     activeChatRoom = selectedUser.id;

  const openUserProfile = (e,user) => {
    e.preventDefault();
    history.push(`/app/users/userProfile/${user.nickname}`);
  }


  const onChatRoomReload = () => {
    if (activeUserTab == 1){
      setChatUsers([]);
          setCurPage(0);
          fetchChatRooms();
    }
  }

  return (
    <Fragment>
      <Breadcrumb
        parent={trans.chat_rooms_title}
        title={trans.chat_rooms_title}
      />
      <Container fluid={true}>
        <Row>
          <Col md="3" className="call-chat-sidebar">
            <Card>
              <CardBody className="chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="media">
                      <Media
                        src={userInfo.img_thumb}
                        style={{objectFit:'cover'}}
                        className="rounded-circle user-image"
                        alt=""
                      />
                      <div className="about">
                        <div className="name font-weight-bold">{userInfo.fullName}</div>
                        <div className="status">@{userInfo.nickname}</div>
                      </div>
                    </div>
                    <div className="people-list">
                      <div className="search">
                        <Form className="theme-form">
                          <FormGroup className="form-group">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="search"
                              defaultValue={searchKeyword}
                              onChange={(e) =>
                                handleSearchKeyword(e.target.value)
                              }
                            />
                            <i className="fa fa-search"></i>
                          </FormGroup>
                        </Form>
                      </div>
                      <div>
                       <Row></Row>
                      <Nav tabs >
                        <NavItem>
                            <NavLink
                            style={{cursor:'pointer'}}
                              className={classnames({ active: activeUserTab == 1 })}
                              onClick={() => { toggleUserTabs('1'); }}
                            >
                              {trans.chat_room_history}
                            </NavLink>
                          </NavItem>
                        <NavItem>
                          <NavLink
                            style={{cursor:'pointer'}}
                            className={classnames({ active: activeUserTab == 2 })}
                            onClick={() => { toggleUserTabs('2'); }}
                          >
                            {trans.menu_coaches}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                           style={{cursor:'pointer'}}
                            className={classnames({ active: activeUserTab == 3 })}
                            onClick={() => { toggleUserTabs('3'); }}
                          >
                            {trans.athletes}
                          </NavLink>
                        </NavItem>
                       
                      </Nav>
                      <br />
                      <div>
                         {activeUserTab == 1 && chatRoomsListData  == null ? 
                           <LoaderBox/>  
                          : activeUserTab == 2 && coachesData  == null ? 
                          <LoaderBox/>  
                          : activeUserTab == 3 && usersListData == null ? 
                          <LoaderBox/>  :
                         <div>
                          <div className="_chat_list">
                            {chatUsers.map((userItem, i) => {
                               var user = userItem;
                              //console.log(user);
                              var hasUnread = false;
                               if (activeUserTab == 1){
                                 if (userItem.userData != null){
                                  user = userItem.userData;
                                  hasUnread = userItem.lastRead == false && userItem.lastMessageUser != userInfo.userId && userItem.lastReadTime != 0;
                                }
                               }

                               

                                return (
                                  <div style={{cursor:'pointer'}} key={`_element_chat_user_${i}_${user.id}`} onClick={(e) => changeChatRoomClick(e, user)}>
                                    <div className="_chat_list_item" > 
                                       <img src={user.img_thumb} className="rounded-circle user-image"  alt="" style={{width:'25px',height:'25px',objectFit:'cover'}} />
                                       <div>
                                         <div>
                                            <span className="name font-weight-bold">{user.fullName}</span>
                                            {hasUnread ? <span className="name font-weight-bold" style={{fontSize:'25px',color:'#F5E707'}}> • </span> : null}
                                         </div>
                                         {userItem.text != null && userItem.text != "" ?
                                         <div>
                                            <span className="">{userItem.text.substring(0,18)}</span>
                                         </div> : null }
                                         <div>
                                         {user.lastOnline != null && user.lastOnline != "" ?  <Moment style={{fontSize:"11px",fontWeight:300,color:'grey'}} locale={userLocale} format={DATE_TIME_FORMAT} unix>{user.lastOnline}</Moment> : null}
                                         </div>
                                       </div>
                                      
                                       
                                    </div>
                                  
                                    <hr/>
                                  </div>
                                )

                                // return (
                                // <div className="_chat_list_item" key={i} onClick={(e) => changeChatRoomClick(e, user)}>
                                //     <img src={user.img_thumb} className="rounded-circle user-image"  alt="" style={{width:'25px',height:'25px',objectFit:'cover'}} />
                                //     <div className={`status-circle _chat_status_circle ${user.chatStatus === true ? "online" : "offline"}`}></div>
                                //     <div style={{marginTop:'0px'}} className="about">
                                //         <div className="name font-weight-bold">{user.fullName}</div>
                                //         <div className="status">{ activeUserTab == 3 ? userItem.text.substring(0,18) :  `@${user.nickname}`}</div>
                                //         {/* <div style={{maxLines:'1'}} className="status">{userInfo.roleName}</div> */}
                                //     </div>
                                //     <hr/>
                                // </div>
                                // );
                            })}
                            
                            </div>
                                
                            <div style={{float: "right"}}>
                             <Pagination aria-label="" className="pagination-secondary">
                                
                                <PaginationItem disabled={curPage <= 0}>
                                
                                <PaginationLink
                                    onClick={e => handlePageClick(e, curPage - 1)}
                                    previous
                                    href="#"
                                />
                                
                                </PaginationItem>

                                {[...Array(usersPages)].map((page, i) => 
                                <PaginationItem active={i === curPage} key={i}>
                                    <PaginationLink onClick={e => handlePageClick(e, i)} href="#">
                                    {i + 1}
                                    </PaginationLink>
                                </PaginationItem>
                                )}

                                <PaginationItem disabled={curPage >= usersPages - 1}>
                                
                                <PaginationLink
                                    onClick={e => handlePageClick(e, curPage + 1)}
                                    next
                                    href="#"
                                />
                                
                                </PaginationItem>
                                
                            </Pagination>
                            </div>
                            
                         </div>
                        }

                      </div>
                    
                       
                    
                        </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="9" className="call-chat-body">
                <Card>
                      <ChatScreen
                        chatRoomPassedId={chatRoomPassedId}
                        selectedUser={selectedUser}
                        onChatRoomReload={onChatRoomReload}
                      />
                </Card>
              </Col>
        </Row>
      </Container>
    </Fragment>
  ) 
};

export default ChatRoom;
