import React, { Fragment,useState,useEffect } from 'react'

import './index.scss';

import {BrowserRouter,Switch,Route,Redirect} from 'react-router-dom'

import { Provider } from 'react-redux';


import AppLayout from './components/appLayout'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import {routes} from './route';
import ConfigDB  from './data/customizer/config'
import {configureFakeBackend ,authHeader, handleResponse} from './services/fack.backend'
import {ConnectedRouter} from 'connected-react-router'
import {useDispatch, useSelector} from "react-redux";
import axios from './util/Api'

import 'react-notifications/lib/notifications.css';



// Signin page
import Signin from './auth/signin'

// Authentication
import PrivateRoute from './PrivateRoute';
import Login from "./pages/authentication/login"
import LoginWithBgImage from "./pages/authentication/loginWithBgImage"
import LoginWithBgVideo from "./pages/authentication/loginWithBgVideo"
import LoginWithValidation from "./pages/authentication/loginwithValidation"
import Register from "./pages/authentication/register"
import RegisterWithBgImage from "./pages/authentication/registerWithBgImage"
import RegisterWithBgVideo from "./pages/authentication/registerWithBgVideo"
import UnlockUser from "./pages/authentication/unlockUser"
import Forgetpwd from "./pages/authentication/forgetpwd"
import Resetpwd from "./pages/authentication/resetpwd"

// Error page
import Error400 from "./pages/errors/error400"
import Error401 from "./pages/errors/error401"
import Error403 from "./pages/errors/error403"
import Error404 from "./pages/errors/error404"
import Error500 from "./pages/errors/error500"
import Error503 from "./pages/errors/error503"

// Comming soo
import Comingsoon from "./pages/comingSoon/comingsoon"
import ComingsoonImg from "./pages/comingSoon/comingsoonImg"
import ComingsoonVideo from "./pages/comingSoon/comingsoonVideo"

// Maintenanc
import Maintenance from "./pages/maintenance"

import Callback from './auth/callback'

import configureStore, {history} from './store';
import { getUser } from './redux/auth/action';
import Loader from "./layout/loader";
import { getTranslations } from './redux/translation/action';
import signUp from './auth/signUp';
import Terms from './pages/terms/terms';
import { askForPermissioToReceiveNotifications } from './firebase/FirebaseInit';
import FaqPage from './pages/faq/faq';
import Privacy from './pages/privacy/privacy';

export const store = configureStore();





// setup fake backend
configureFakeBackend();



const Root = (props) =>  {
  
   const [anim, setAnim] = useState("");
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const abortController = new AbortController();


  useEffect(() => {


    askForPermissioToReceiveNotifications();

      // const requestOptions = { method: 'GET', headers: authHeader() };
      // fetch('/users', requestOptions).then(handleResponse)
      setAnim(animation)
      //firebase_app.auth().onAuthStateChanged(setCurrentUser);
      //setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
      console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
      console.disableYellowBox = true;
      return function cleanup() {
          abortController.abort();
      }


      // eslint-disable-next-line 
    }, []);


    return(
      <Fragment>
        <Provider store={store}>
        <ConnectedRouter history={history}>
        <BrowserRouter basename={`/`}>
        
        <Switch>

     

          <PrivateRoute  path={`${process.env.PUBLIC_URL}/login`} exact component={Signin} />
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/register`} exact component={signUp}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/forgetPwd`} exact component={Forgetpwd} />
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/change-password/:resetPassToken`} exact component={Resetpwd}/>

          <PrivateRoute  path={`${process.env.PUBLIC_URL}/terms/:lang?`} exact component={Terms} />
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/faq/:lang?`} exact component={FaqPage} />
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/privacy-policy/:lang?`} exact component={Privacy} />

          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/login`} component={Login}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg1`} component={LoginWithBgImage}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg2`} component={LoginWithBgVideo}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/loginWithValidation`} component={LoginWithValidation}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/signup`} component={Register}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg1`} component={RegisterWithBgImage}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg2`} component={RegisterWithBgVideo}/>
        
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/unlockUser`} component={UnlockUser}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/auth/resetPwd`} component={Resetpwd}/>

          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503}/>
          
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon`} component={Comingsoon}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoonImg`} component={ComingsoonImg}/>
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoonVideo`} component={ComingsoonVideo}/>

          <PrivateRoute  path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance}/>
          
          <PrivateRoute  path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback/>} />
         
          <PrivateRoute path={`${process.env.PUBLIC_URL}/not-found`} exact  component={Error404}/>
          
          <PrivateRoute>
          <AppLayout>

          <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
              return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/default`} />)
          }} /> 
          
          <TransitionGroup>
              {routes.map(({ path, Component }) => (
                <Route key={path}  exact  path={`${process.env.PUBLIC_URL}${path}`}>
                    {({ match }) => (
                        <CSSTransition 
                          in={match != null}
                          timeout={100}
                          classNames={anim} 
                          unmountOnExit>
                          <div><Component/></div>
                        </CSSTransition> 
                    )}
                </Route>
                ))}
          </TransitionGroup> 
          
          </AppLayout> 
          </PrivateRoute>
 
      
        </Switch>
        
        
        </BrowserRouter>
        </ConnectedRouter>
        </Provider>
      </Fragment>
      )
}

export default Root;
