import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import {useDispatch, useSelector} from 'react-redux';
import {userSignOut} from '../../redux/auth/action';
import { FileText, LogIn, Mail, User, MessageSquare, Bell, Minimize, Search, ShoppingCart, Minus, Plus, X } from 'react-feather';
import { useHistory } from 'react-router-dom'
import { firebase_app } from '../../data/config'
import {useAuth0} from '@auth0/auth0-react'
import Bookmark from "../../layout/bookmark"
import {Link} from 'react-router-dom'
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

import {English,Deutsch,Español,Français,Português,简体中文,Notification,DeliveryProcessing,OrderComplete,TicketsGenerated,DeliveryComplete,CheckAllNotification,ViewAll,MessageBox,EricaHughes,KoriThomas,Admin,Account,Inbox,Taskboard,LogOut,AinChavez,CheckOut,ShopingBag,OrderTotal,GoToShopingBag, DATE_TIME_FORMAT} from '../../constant'

import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { fetchChatRoomsList } from '../../redux/chat/action';
import LoaderBox from '../../components/reuse/loaderbox';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { postNotificationsList } from '../../redux/settings/action';
import SweetAlert from 'sweetalert2';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();



const Rightbar = (props) => {    

  const dispatch = useDispatch();

  const userLocale = General.getLocale();


  const history = useHistory();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('')
  const [searchresponsive, setSearchresponsive] = useState(false)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("en")
  const [cartDropdown, setCartDropDown] = useState(false)
  const [notificationDropDown, setNotificationDropDown] = useState(false)
  const [chatDropDown, setChatDropDown] = useState(false)
  const {logout} = useSelector(({auth}) => auth);
  const userInfo = useSelector(({auth}) => auth.authUser);
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const notificationsData = useSelector(({settings}) => settings.notificationsData);
  const trans = useSelector(({translation}) => translation.translationsData);

  const chatRoomsListData = useSelector(({chat}) => chat.chatRoomsListData);  
  const [chatRoomMessages, setChatRoomMessages] = useState([]);
  const [notificationList, setNotificationList] = useState([]);


  // console.log("transesss",trans);
  // console.log("settingsdata",settingsData);

  // if (settingsData){
  // alert(settingsData.settings.upload_limit)
  // } else {
  //   alert("settigns null")
  // }

  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
    setName(localStorage.getItem('Name'))
    if(localStorage.getItem("layout_version") === "dark-only"){
      setMoonlight(true)
    }
    fetchChatRooms();
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (chatRoomsListData != null){
        var ar = [];
        chatRoomsListData.rooms.forEach(element => {
                ar.push(element);
        });
        setChatRoomMessages(ar);
    }
  }, [chatRoomsListData]);

  useEffect(() => {
    if (notificationsData != null){
        var ar = [];
        notificationsData.data.forEach(element => {
                ar.push(element);
        });
        setNotificationList(ar);
    }
  }, [notificationsData]);


  


  const fetchNotifications = () => {
    let postData = {
        "page": 1,
        "sort": {"_id":-1}
    };
    dispatch((postNotificationsList(postData)));
  }


  const fetchChatRooms = () => {
    let postData = {
      page: 1,
      sort: { _id: -1 },
      query: {},
      searchKeyword: "",
      fromChat: true
    };
    dispatch(fetchChatRoomsList(postData));
  }

  useEffect(() => {
    if (logout && logout.status){
      history.replace('/');
    }
  }, [logout]);

  
  const  logoutFromApi = () =>  {
    SweetAlert.fire({
      title:trans.logout_title,
      text:trans.logout_descr,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.dialog_yes,
      cancelButtonText: trans.dialog_no,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        let postData = {userId: userInfo.userId}
        dispatch(userSignOut(postData));
      } else {
        
      }
    });
   
  }

  const RedirectToChats = (e,chatRoom) => {
    e.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/app/chat/${chatRoom.id}`)
  }

  const RedirectToCart = () => {
    history.push(`${process.env.PUBLIC_URL}/app/ecommerce/cart`)
  }

  const UserMenuRedirect = (redirect) => {
    history.push(redirect)
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
      document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  const openChat = (e) => {
    e.preventDefault();
    history.push(`/app/chat`);
  }

  const openPushAll= (e) => {
    e.preventDefault();
    history.push(`/app/notifications`);
  }

  return (
    <Fragment>
     {settingsData.settings.upload_limit}
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          {/* <li className="language-nav">
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
              <div className="current_lang">
                <div className="lang" onClick={() => LanguageSelection(langdropdown)}>
                  <i className={`flag-icon flag-icon-${selected === "en" ? "us" : selected === "du" ? "de" : selected}`}></i>
                  <span className="lang-txt">{selected}</span>
                </div>
              </div>
              <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
                <div className="lang" onClick={() => handleSetLanguage('en')}><i className="flag-icon flag-icon-us"></i><span className="lang-txt">{English}<span> {"(US)"}</span></span></div>
                <div className="lang" onClick={() => handleSetLanguage('du')}><i className="flag-icon flag-icon-de"></i><span className="lang-txt">{Deutsch}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('es')}><i className="flag-icon flag-icon-es"></i><span className="lang-txt">{Español}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('fr')}><i className="flag-icon flag-icon-fr"></i><span className="lang-txt">{Français}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('pt')}><i className="flag-icon flag-icon-pt"></i><span className="lang-txt">{Português}<span> {"(BR)"}</span></span></div>
                <div className="lang" onClick={() => handleSetLanguage('cn')}><i className="flag-icon flag-icon-cn"></i><span className="lang-txt">{简体中文}</span></div>
                <div className="lang" onClick={() => handleSetLanguage('ae')}><i className="flag-icon flag-icon-ae"></i><span className="lang-txt">{"لعربية"}<span> {"(ae)"}</span></span></div>
              </div>
            </div>
          </li> */}
          {/* <li><span className="header-search"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li> */}
          <li className="onhover-dropdown">
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell />
              {/* <span className="badge badge-pill badge-secondary">2</span> */}
            </div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
              <li>
                <Bell />
                <h6 className="f-18 mb-0">{trans.my_notifications}</h6>
              </li>
           
              { notificationList.length > 0 ? 
                <li>
                <p><i className="fa fa-circle-o mr-3 font-primary"> </i>{notificationList[0].title} <span className="pull-right"><Moment className="f-12" fromNow={true} className="message-data-time" locale={userLocale}  unix>{notificationList[0].timestamp}</Moment></span></p>
                </li>
              : null}

            { notificationList.length > 1 ? 
                <li>
                <p><i className="fa fa-circle-o mr-3 font-primary"> </i>{notificationList[1].title} <span className="pull-right"><Moment className="f-12" fromNow={true} className="message-data-time" locale={userLocale}  unix>{notificationList[1].timestamp}</Moment></span></p>
                </li>
              : null}

              { notificationList.length > 2 ? 
                <li>
                <p><i className="fa fa-circle-o mr-3 font-primary"> </i>{notificationList[2].title} <span className="pull-right"><Moment className="f-12" fromNow={true} className="message-data-time" locale={userLocale}  unix>{notificationList[2].timestamp}</Moment></span></p>
                </li>
              : null}

            { notificationList.length > 3 ? 
                <li>
                <p><i className="fa fa-circle-o mr-3 font-primary"> </i>{notificationList[3].title} <span className="pull-right"><Moment className="f-12" fromNow={true} className="message-data-time" locale={userLocale}  unix>{notificationList[3].timestamp}</Moment></span></p>
                </li>
              : null}

              <li><button className="btn btn-primary" onClick={(e)=> openPushAll(e)} >{trans.chat_view_all}</button>
              </li>
            </ul>
          </li>
          {/* <Bookmark/> */}
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          {/* <li className="cart-nav onhover-dropdown">
            <div className="cart-box" onClick={() => setCartDropDown(!cartDropdown)}><ShoppingCart/><span className="badge badge-pill badge-primary">{"2"}</span></div>
            <ul className={`cart-dropdown onhover-show-div ${cartDropdown ? "active" : ""}`}>
              <li>
                <h6 className="mb-0 f-20">{ShopingBag}</h6><ShoppingCart/>
              </li>
              <li className="mt-0">
                <div className="media" onClick={RedirectToCart}><img className="img-fluid rounded-circle mr-3 img-60" src={require("../../assets//images/ecommerce/01.jpg")} alt=""/>
                  <div className="media-body"><span>{"V-Neck Shawl Collar Woman's Solid T-Shirt"}</span>
                    <p>{"Yellow(#fcb102)"}</p>
                    <div className="qty-box">
                      <InputGroup>
                          <InputGroupAddon addonType="prepend">
                              <button className="btn quantity-left-minus" type="button" data-type="minus" data-field=""><Minus/></button>
                          </InputGroupAddon>
                            <input className="form-control input-number" type="text" name="quantity" defaultValue="1"/>
                          <InputGroupAddon addonType="prepend">
                              <button className="btn quantity-right-plus" type="button" data-type="plus" data-field=""><Plus/></button>
                          </InputGroupAddon>
                      </InputGroup>
                    </div>
                    <h6 className="text-right text-muted">{"$299.00"}</h6>
                  </div>
                  <div className="close-circle"><a href="#javascript"><X/></a></div>
                </div>
              </li>
              <li className="mt-0">
                <div className="media" onClick={RedirectToCart}><img className="img-fluid rounded-circle mr-3 img-60" src={require("../../assets//images/ecommerce/03.jpg")} alt=""/>
                  <div className="media-body"><span>{"V-Neck Shawl Collar Woman's Solid T-Shirt"}</span>
                    <p>{"Yellow(#fcb102)"}</p>
                    <div className="qty-box">
                      <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <button className="btn quantity-left-minus" type="button" data-type="minus" data-field=""><Minus/></button>
                          </InputGroupAddon>
                          <input className="form-control input-number" type="text" name="quantity" defaultValue="1"/>
                          <InputGroupAddon addonType="prepend">
                            <button className="btn quantity-right-plus" type="button" data-type="plus" data-field=""><Plus/></button>
                          </InputGroupAddon>
                      </InputGroup>
                    </div>
                    <h6 className="text-right text-muted">{"$299.00"}</h6>
                  </div>
                  <div className="close-circle"><a href="#javascript"><X/></a></div>
                </div>
              </li>
              <li>
                <div className="total">
                  <h6 className="mb-2 mt-0 text-muted">{OrderTotal} : <span className="f-right f-20">{"$598.00"}</span></h6>
                </div>
              </li>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/product`}><Button color="primary" className="btn btn-block view-cart">{GoToShopingBag}</Button></Link>
                <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/checkout`}><Button color="secondary" className="btn-block view-cart mt-2">{CheckOut}</Button></Link>
              </li>
            </ul>
          </li> */}
          <li className="onhover-dropdown" onClick={() => setChatDropDown(!chatDropDown)}><MessageSquare />
            <ul className={`chat-dropdown onhover-show-div ${chatDropDown ? "active" : ""}`}>
              <li>
                <MessageSquare />
                <h6 className="f-18 mb-0">{trans.chat_rooms_title}</h6>
              </li>
              { chatRoomMessages.length > 0 ? 
              <li onClick={(e) => RedirectToChats(e,chatRoomMessages[0])}>
                <div className="media"><img     style={{objectFit:'cover',width:'30px',height:'30px'}} className="img-fluid rounded-circle mr-3" src={chatRoomMessages[0].userData.img_thumb} alt="" />
                  <div className="status-circle online"></div>
                  <div  className="media-body"><span style={{fontFamily:'inherit'}}>{chatRoomMessages[0].userData.fullName}</span>
                    <p style={{maxLines:'2'}}>{chatRoomMessages[0].text}</p>
                  </div>
                  <Moment className="f-12" fromNow={true} className="message-data-time" locale={userLocale}  unix>{chatRoomMessages[0].timestamp}</Moment>
                  {/* <p className="f-12 font-success">{"58 mins ago"}</p> */}
                </div>
              </li>
              : null}
             { chatRoomMessages.length > 1 ? 
              <li onClick={(e) =>RedirectToChats(e,chatRoomMessages[1])}>
                <div className="media"><img     style={{objectFit:'cover',width:'30px',height:'30px'}} className="img-fluid rounded-circle mr-3" src={chatRoomMessages[1].userData.img_thumb} alt="" />
                  <div className="status-circle online"></div>
                  <div  className="media-body"><span style={{fontFamily:'inherit'}}>{chatRoomMessages[1].userData.fullName}</span>
                    <p style={{maxLines:'2'}}>{chatRoomMessages[1].text}</p>
                  </div>
                  <Moment className="f-12" fromNow={true} className="message-data-time" locale={userLocale}  unix>{chatRoomMessages[1].timestamp}</Moment>
                  {/* <p className="f-12 font-success">{"58 mins ago"}</p> */}
                </div>
              </li>
              : null}
             { chatRoomMessages.length > 2 ? 
              <li onClick={(e) =>RedirectToChats(e,chatRoomMessages[2])}>
                <div className="media"><img     style={{objectFit:'cover',width:'30px',height:'30px'}}  className="img-fluid rounded-circle mr-3" src={chatRoomMessages[2].userData.img_thumb} alt="" />
                  <div className="status-circle online"></div>
                  <div className="media-body"><span  style={{fontFamily:'inherit'}}>{chatRoomMessages[2].userData.fullName}</span>
                    <p style={{maxLines:'2'}}>{chatRoomMessages[2].text}</p>
                  </div>
                  <Moment className="f-12" fromNow={true} className="message-data-time" locale={userLocale}  unix>{chatRoomMessages[2].timestamp}</Moment>
                  {/* <p className="f-12 font-success">{"58 mins ago"}</p> */}
                </div>
              </li>
              : null}
              <li className="text-center"> <button onClick={(e)=>openChat(e)} className="btn btn-primary">{trans.chat_view_all}     </button></li>
            </ul>
          </li>
          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className=" rounded-circle shadow-sm" style={{objectFit: "cover"}} src={userInfo ? userInfo.img_thumb : "#"} alt="" />
              <div className="media-body"><span>{userInfo ? userInfo.fullName : "Admin"}</span>
                <p className="mb-0 font-roboto">{userInfo ? userInfo.roleName : ""} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/userProfile/${userInfo.nickname}`)}><User /><span>{Account} </span></li>
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/chat`)}><Mail /><span>{Inbox}</span></li>
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/userSettings`)}><FileText /><span>{trans.menu_settings}</span></li>
              <li onClick={logoutFromApi}><LogIn /><span>{trans.menu_logout}</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
export default translate(Rightbar);