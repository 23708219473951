import "./users.css";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Button,
  Form,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  DATE_TIME_FORMAT,
  Follower,
  Following,
  TotalPost,
} from "../../constant";
import axios from "axios";
import { STORED_TRANSLATIONS } from "../../redux/actionTypes";
import { getAdminUsers } from "../../redux/user/action";
import { Loader } from "react-bootstrap-typeahead";
import Moment from "react-moment";
import * as General from "../../util/General";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import NoAccess from "../../components/reuse/noaccess";
import AddUserModal from "./userAddModal";
import LoaderBox from '../../components/reuse/loaderbox';

const UserList = (props) => {
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const usersListData = useSelector(({ user }) => user.usersListData);
  const userIsAdmin = useSelector(({ auth }) => auth.userIsAdmin);
  const userIsManager = useSelector(({auth}) => auth.userIsManager);
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach);


  const [cards, setCards] = useState([]);
  const trans = useSelector(({ translation }) => translation.translationsData);
  const [searchText, setSearchText] = useState("");

  var searchKeyword = "";

  const [curPage, setCurPage] = useState(0);

  const [addUserToggle, setAddUserToggle] = useState(false);

  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = () => {
    let postData = {
      page: curPage + 1,
      sort: { _id: -1 },
      query: {},
      searchKeyword: searchKeyword,
    };
    dispatch(getAdminUsers(postData));
  };

  useEffect(() => {
    getUsersData();
  }, [curPage]);

  const addEditUserModaltoggle = () => {
    setAddUserToggle(true);
  };

  const handleSearchKeyword = async (keyword) => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getUsersData();
  };

  const handleUserEditClick = (e, user) => {
    e.preventDefault();
    history.push(`/app/users/userEdit/${user.nickname}`);
  };

  const removeUser = (user) => {};

  const handlePageClick = (e, newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  };

  const handleUserClick = (e, user) => {
    e.preventDefault();
    history.push(`/app/users/userProfile/${user.nickname}`);
  };

  const onAddUserModalClose = () => {
    setAddUserToggle(false);
  };

  const onUserStored = () => {
    setCurPage(0);
    getUsersData();
  };

  return (
    <Fragment>
      <Breadcrumb parent={trans.menu_users} title={trans.menu_users} />
      <Container fluid={true}>
        {userIsManager || userIsCoach ||  userIsAdmin ? usersListData != null ? 
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xl="12" sm="12">
                      <div style={{ alignItems: "center" }}>
                        <div
                          className="card-title mb-0"
                          style={{ float: "left" }}
                        >
                          <h5 className="font-weight-bold">
                            {trans.data_total}: {usersListData.total} •{" "}
                            {trans.data_today}: {usersListData.todayCount}
                          </h5>
                        </div>

                        <div
                          className="card-title mb-0"
                          style={{
                            float: "right",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Button
                            style={{ marginRight: "10px" }}
                            onClick={addEditUserModaltoggle}
                            color="primary"
                            size="sm"
                          >
                            <i className="fa fa-plus"></i> {trans.add_btn}
                          </Button>

                          <Form>
                            <FormGroup className="m-0">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder={trans.search_hint}
                                value={searchText}
                                onChange={(e) =>
                                  handleSearchKeyword(e.target.value)
                                }
                              />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <br />
                    <br />
                  </Row>
                </CardHeader>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr className="_table_user_item">
                        <th key={"user_img"}></th>
                        <th key={"user_nickname"}>{trans.nickname_field}</th>
                        <th key={"user_fullname"}>{trans.fullname_field}</th>
                        <th key={"user_email"}>{trans.email_field}</th>
                        <th key={"user_joined"}>{trans.user_joined}</th>
                        <th key={"user_roles"}>{trans.user_roles_field}</th>
                        <th key={"user_workouts"}>{trans.last_online_field}</th>
                        <th key={"trans_edit"}></th>
                        <th key={"trans_delete"}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersListData.data.map((item, i) => {
                        // let values = item.values.map((value,valIndex) => {
                        //    return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        // })

                        let toProfile = `/app/users/userProfile/${item.nickname}`;
                        let toEditProfile = `/app/users/userEdit/${item.nickname}`;

                        return (
                          <tr
                            role="button"
                            key={`user_item_${i}`}
                            className="_table_user_item"
                          >
                            <td>
                              <Link to={toProfile}>
                                <img
                                  className="rounded-circle shadow-sm"
                                  style={{
                                    objectFit: "cover",
                                    width: 40,
                                    height: 40,
                                  }}
                                  src={item.img_thumb}
                                  alt="40x40"
                                  data-holder-rendered="true"
                                />
                              </Link>
                            </td>
                            <td><Link to={toProfile}><span>@{item.nickname}</span></Link></td>
                            <td>{item.fullName}</td>
                            <td>{item.email}</td>
                            <td>
                              {" "}
                              <Moment
                                locale={userLocale}
                                format={DATE_TIME_FORMAT}
                              >
                                {item.createdAt}
                              </Moment>
                            </td>
                            <td>{item.roleName}</td>
                            <td>{<Moment
                                locale={userLocale}
                                format={DATE_TIME_FORMAT} unix>
                                {item.lastOnline}
                              </Moment>}</td>
                            {/* <td>{item.values[0].isApp  == 1 ? "YES" : "NO" }</td> */}
                            <td className="text-right">
                              <Link to={toProfile}>
                                <Button color="primary" size="sm">
                                  <i className="fa fa-eye"></i>
                                </Button>
                              </Link>
                            </td>
                            <td className="text-right">
                              <Link to={toEditProfile}>
                                <Button color="primary" size="sm">
                                  <i className="fa fa-pencil"></i>
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <CardFooter>
                  <div style={{ float: "right" }}>
                    <Pagination aria-label="" className="pagination-secondary">
                      <PaginationItem disabled={curPage <= 0}>
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage - 1)}
                          previous
                          href="#"
                        />
                      </PaginationItem>

                      {[...Array(usersListData.pages)].map((page, i) => (
                        <PaginationItem active={i === curPage} key={i}>
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i)}
                            href="#"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem
                        disabled={curPage >= usersListData.pages - 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage + 1)}
                          next
                          href="#"
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
         : 
          <LoaderBox /> : 
            <Col md="12">
              <NoAccess />
            </Col>
        }

        <AddUserModal
          onAddUserModalClose={onAddUserModalClose}
          open={addUserToggle}
          onUserStored={onUserStored}
        />
      </Container>
    </Fragment>
  );
};

export default UserList;
