import './trainings.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { useHistory } from 'react-router';
import { getTrainings } from '../../redux/trainings/action';
import { toast } from 'react-toastify';
import NoAccess from '../../components/reuse/noaccess';
import GetTrainingModal from './getTrainingModal';
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';

const TrainingsPicker = (props) => {
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const checkSubData = useSelector(({subscription}) => subscription.checkSubData);
  const addTrainingsRreponse = useSelector(({trainings}) => trainings.addTrainingsData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);

  const [getTrainingToggle, setGetTrainingToggle] = useState(false);
  const [getTrainingItem, setGetTrainingItem] = useState(null);

  const [trainingPlans, setTrainingPlans] = useState([]);

  useEffect(() => {
    getTrainingssData();
    getUserSubs();
  }, []);

  useEffect(() => {
      if (trainingsData != null){
        setTrainingPlans(trainingsData.trainings);
      }
  }, [trainingsData]);

 
  const getTrainingssData = () => {
    setTrainingPlans([]);
    let postData = {page: curPage+1,sort: {"_id": -1},query: {},searchKeyword: searchKeyword}
    dispatch(getTrainings(postData));
  }

  const getUserSubs = () => {
    let postData = {}
    dispatch(checkUserSub(postData));
  }
 
  useEffect(() => {
    getTrainingssData();
  }, [curPage]);

  

  const addEditTrainingsModaltoggle = () => {

  }

  const handleSearchKeyword = async (keyword)   => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getTrainingssData();
  }

  const handleTrainingsEditClick = (e,user) => {
    e.preventDefault();
    // history.push(`/app/users/userEdit/${user.nickname}`);
  }

  const removeTrainings = (user)=> {

  }

  const handleFeedBackAnswer = (e,item) => {
    e.preventDefault();
    if ( item != null){
      window.location = `mailto:${item.email}`
    }
  }

  const openGetTraining = (e,item,index,userHasSub) => {
    e.preventDefault();
    if (!userHasSub){
      setGetTrainingItem(item);
      setGetTrainingToggle(true);
    }
  }



  function rednerTrainingPick(item,index) {
    let userHasSub = false;
    let buttonText = trans.training_pick_start_now;
    checkSubData.subscriptions.forEach(sub => {
        if (sub.planId.includes(item.plan_id)){
          userHasSub = true;
          buttonText = trans.training_already_sub;
        }
    });

      return (<div key={`training_pick_${item.id}`} className="_training_pick_item">
          <div className="_training_pick_head_items">
            <h3 className="_training_pick_item_title">{item.title}</h3>
            <Row>
             <Col md="6">
              <h3 className="_training_pick_item_price">{`€${item.price}`}</h3>
               <p className="_training_pick_item_per_month">{trans.per_month}</p>
              </Col>
              <Col md="6">
              <h3 className="_training_pick_item_price">{`€${item.price_year}`} <span style={{color:'red'}}> {` -${item.discount}%`}</span></h3>
               <p className="_training_pick_item_per_month">{trans.per_year}</p>
              </Col>
            </Row>
         
          </div>
          <div style={{height:'1px',backgroundColor:'white'}}></div>
          <div onClick={(e) => openGetTraining(e,item,index,userHasSub)} style={{backgroundColor:userHasSub ? "grey" : "#F5E707"}} className="_training_pick_start_now_btn">
            <h3 className={userHasSub ? "_training_pick_start_now_text" : "_training_pick_start_now_text _training_pick_start_now_text_active"}>{buttonText}</h3>
          </div>

          <div className="_training_pick_desc_container">
            <p className="_training_pick_desc_text">{item.desc}</p>
          </div>

      </div>)
  }

  const onGetTrainingModalClose = () => {
    setGetTrainingToggle(false);
  }

  const onGetTrainingSubscribe = () => {
    window.location.reload();

  }


  return (
    <Fragment>
      <Container fluid={true}>

      <Row>
    


            <Col md="12">
              <Card>
                <CardHeader className="_training_picker_header">
                <h4 className="font-weight-bold" >{trans.training_pick_header}</h4>

                </CardHeader>
                {trainingsData != null && checkSubData != null ? 
                <CardBody>

                <div className="_training_pick_list">
                  {trainingPlans.map((item,i) => {
                    return rednerTrainingPick(item,i)
                  })}
                </div>
                </CardBody>
                : <LoaderBox/>}
                
               
              </Card>
            </Col>
          </Row>

        <GetTrainingModal
          open={getTrainingToggle}
          getTrainingItem={getTrainingItem}
          onGetTrainingModalClose={onGetTrainingModalClose}
          onGetTrainingSubscribe={onGetTrainingSubscribe}
        />

      </Container>
    </Fragment>
  );
}

export default TrainingsPicker;