import './users.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import UserStatus from './userStatus';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import { MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, DATE_TIME_FORMAT} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import LoaderBox from '../../components/reuse/loaderbox';
import { getTrainings } from '../../redux/trainings/action';
import { createCustomSub, customSubs, deleteCustomSub } from '../../redux/subscription/action';
import Moment from 'react-moment';
import SweetAlert from 'sweetalert2';
import { CREATE_CUSTOM_SUB,DELETE_CUSTOM_SUB, FETCH_VIEW_USER_DATA } from '../../redux/actionTypes';

const UserEdit = (props) => {
  const dispatch = useDispatch();

  //IN THIS COMPONENTS WE CAN EDIT THE USER
 
  const userLocale = General.getLocale();
  //LETS GET THE TRANSLATIONS and settings
  const trans = useSelector(({translation}) => translation.translationsData);
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const customSubsData = useSelector(({subscription}) => subscription.customSubsData);
  const createCustomSubsData = useSelector(({subscription}) => subscription.createCustomSubsData);
  const deleteCustomSubsData = useSelector(({subscription}) => subscription.deleteCustomSubsData);

  //LETS GET THE LOGGED IN USER DATA
  const userInfo = useSelector(({auth}) => auth.authUser);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const editUserData = useSelector(({auth}) => auth.editUser);
  const forgotPassData = useSelector(({auth}) => auth.forgotPass);
  const updateUserRolesData = useSelector(({auth}) => auth.updateUserRoles);

  //LETS GET THE VIEW USER DATA
  const userView = useSelector(({user}) => user.userViewData);

  //SO LETS CREATE A BOOLEAN TO HOLD IF THE USER IS EDITABLE
  const [canEditUser,setCanEditUser] = useState(false);
  const [isAdmin,setIsAdmin] = useState(false);

  //LETS GRAB THE PASSED NICKNAME
  var userNickname = "";
  let { id } = useParams();
  if (id != null){
    userNickname = id;
  }

  //INITIAL DATA
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  const [birthDate,setBirthDate] = useState("");
  const [gender,setGender] = useState(0);
  const [nation,setNation] = useState("");
  const [bio,setBio] = useState("");
  const [height,setHeight] = useState(0.0);
  const [weight,setWeight] = useState(0.0);
  const [contact_method_value, setContactMethodValue] = useState('');
  const [contact_method_type, setContactMethodType] = useState(1);
  const [contact_method_receiveServices, setContactMethodReceiveServices] = useState(true);
  const [contact_methods, setContactMethods] = useState([]);

  const [userRoleValue, setUserRoleValue] = useState('');
  const [userRolesList, setUserRolesList] = useState([]);
  const [edditingRoles, setEdditingRoles] = useState(false);


  const [fromAdminTrainingId,setFromAdminTrainingId] = useState(null);
  const [fromAdminExpireDate,setFromAdminExpireDate] = useState(new Date());

  const [userRolesSelectionItems, setUserRolesSelectionItems] = useState([]);
  

   //FIRST WE NEED TO CHECK IF THE USERNICKNAME IS THE SAME AS THE LOGGED IN USER NICKNAME
  //NOW WHEN THE COMPONENT LOAD WE WILL DO OUR CHECKS
  useEffect(() => {
    //REQUEST THE DATA OF THE USER WE ARE CURRENTLY VIEWING
    getUserViewData();

      //IF BOTH USERS HAVE THE SAME ID THEN WE CAN EDIT
      if (userInfo.nickname == userNickname){
        setCanEditUser(true);
      }

      getTrainingssData();

      
      //USER CAN ALSO EDIT OTHER USERS IF HE IS A MANAGER OR SUPER ADMIN
      // userInfo.roles.forEach(userRole => {
      //   if (userRole.role_id == 1 || userRole.role_id == 2){
      //     setCanEditUser(true);
      //     setIsAdmin(true);
      //     return;
      //   }
      // });

      if (userIsAdmin){
        setCanEditUser(true);
        setIsAdmin(true);
      }

      return () => {
        //CLEAR DATA WHEN COMPONENT DIES
        dispatch({type: FETCH_VIEW_USER_DATA, payload: null});
      }

  },[])

  const getTrainingssData = () => {
    let postData = {page: 1,sort: {"_id": 1},query: {},searchKeyword: "",fromApp:true}
    dispatch(getTrainings(postData));
  }

  const getCustomSubsData = () => {
    const userId = userView.userInfo.userId;
    let postData = {
      "query": {userId:userId,fromAdmin: true}
   }
   dispatch(customSubs(postData));
  }

  useEffect(() => {
    if (userView !=null){
      console.log(userView);
      //USER DATA LOADED LETS UPDATE THE VALUES
      setFirstName(userView.userInfo.firstName);
      setLastName(userView.userInfo.lastName);
      if (userView.userInfo.birthDate != null && userView.userInfo.birthDate != ""){
        setBirthDate(new Date(userView.userInfo.birthDate));
      }
      
      setGender(userView.userInfo.gender);
      if(userView.userInfo.nation != null && userView.userInfo.nation != ""){
       setNation(userView.userInfo.nation._id);
      } 
      setBio(userView.userInfo.bio);
      // console.log(userView);
      setContactMethods(userView.userInfo.contact_methods);
      
     
      let newRoles = [];
      userView.userInfo.roles.forEach((item, i) => {
        newRoles.push(item._id);
      });
      setUserRolesList(newRoles);
      
      
      getCustomSubsData();

      setHeight(userView.userInfo.height);

      setWeight(userView.userInfo.weight);

      
      
    }
  },[userView])

  //GET THE USER WE WANT TO SEE DATA
  const getUserViewData = () =>{
    let postData = {
      nickname: userNickname
    };
    dispatch(getUserData(postData))
  }

  //LOOK FOR CHANGES IN EDIT ACCOUNT
  useEffect(() => {
    if (editUserData !=null){
      if (editUserData.status){
        toast.success(editUserData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          getUserViewData();
      } else {
        toast.error(editUserData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      }
     
    }
  },[editUserData])

  useEffect(() => {
    if (updateUserRolesData !=null){
      if (updateUserRolesData.status){
          getUserViewData();
      } else {
        toast.error(editUserData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      }
     
    }
  },[updateUserRolesData])


  //CALL THIS TO UPDATE USER DATA
  const updateUserProfile = (e) => {
    e.preventDefault();

    let postData = {
      userId: userView.userInfo.userId,
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate,
      gender: gender,
      nation: nation,
      bio: bio,
      contact_methods:contact_methods,
      height: height,
      weight: weight
    }

    console.log(postData);

    dispatch(editUser(postData))
  }

  const updateUserRoles = () => {
    let postData = {
      userId: userView.userInfo.userId,
      userRoles: userRolesList
    }
    dispatch(editRoles(postData))
  }

  //HANDLE FORGOT OPASS DATA
  useEffect(() => {
    if (forgotPassData != null) {
      console.log(forgotPassData);
      if (forgotPassData.status){
        toast.success(forgotPassData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      } else {
        toast.error(forgotPassData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      }
    }
  }, [forgotPassData]);

  useEffect(() => {
    if (trainingsData != null) {
      if (trainingsData.trainings) {
        setFromAdminTrainingId(trainingsData.trainings[0].id)
      }
    }
  }, [trainingsData]);

  useEffect(() => {
    if (createCustomSubsData != null) {
        getCustomSubsData();
        dispatch({type: CREATE_CUSTOM_SUB, payload: null});
    }
  }, [createCustomSubsData]);

  useEffect(() => {
    if (deleteCustomSubsData != null) {
        getCustomSubsData();
        dispatch({type: DELETE_CUSTOM_SUB, payload: null});
    }
  }, [deleteCustomSubsData]);
  

  //SEND A FORGOT PASSWORD EMAIL TO USER
  const sendForgotPassword = (e) => {
    e.preventDefault();
    
    let postData = {email: userView.userInfo.email}
    dispatch(forgotPass(postData));
  }


  //HANDLE BIRTHDAY DATE CHANGES
  const handleBirthDateChange = date => {
    setBirthDate(date);
  };


  const openAddContactMethod = (e) => {
    e.preventDefault();

  }

  const handleFromAdminTrainingId = (e) => {
    setFromAdminTrainingId(e.target.value);
  }

  const handleContactMethodType = (e) => {
    setContactMethodType(e.target.value);
  }

  const handleUserRoleValue = (e) => {
    setUserRoleValue(e.target.value);
  }

  const addContactMethodItem = (e)  => {
    e.preventDefault();
    var uniqid = Date.now();
    let contactItem = {local_id: uniqid,value:contact_method_value,type:contact_method_type,receiveServices:contact_method_receiveServices};
    contact_methods.push(contactItem);
    setContactMethodValue("");
    setContactMethodType(1);
    setContactMethods(contact_methods);
  }

  const onOpenDeleteContactMethod = (e,itemContactMethod) => {
    e.preventDefault();
    if (itemContactMethod._id != null){
      setContactMethods(contact_methods => contact_methods.filter(item => item._id != itemContactMethod._id));
    } else {
      setContactMethods(contact_methods => contact_methods.filter(item => item.local_id != itemContactMethod.local_id));
    }
  }

  const onOpenDeleteUserRole = (e,userRole) => {
    e.preventDefault();
    if (userRole._id != null){
      setEdditingRoles(true);
      setUserRolesList(userRoles => userRoles.filter(item => item != userRole._id));
    } 
  }
  

  let contactMethodsTableItems = [];
  if (userView != null){
 //console.log("contact_methods", contact_methods);
    contactMethodsTableItems = contact_methods.map((detail, index) => {
      let valueText = "";
      settingsData.contactMethods.forEach((item, i) => {
          if (item.value == detail.type){
            valueText = item.label;
          }
      });

     
      return <tr  hover key={index}>
          <th>{valueText} </th>
          <th>{detail.value}</th>
          <th className="pointer"  onClick={(e) => { onOpenDeleteContactMethod(e,detail)}}><button className="btn btn-primary">{trans.delete_btn}</button></th>
        </tr>
    });
  }

  useEffect(() => {
      if (edditingRoles){
        setEdditingRoles(false);
        updateUserRoles();
      }
      setUserRolesSelection();
  }, [userRolesList,edditingRoles]);


  const addUserRoleItem = (e) => {
      e.preventDefault();

      console.log(userRoleValue);

      setEdditingRoles(true);
      let newRoles = [];
      userRolesList.forEach((item,i) => {
        newRoles.push(item);
      });
      newRoles.push(userRoleValue);
      console.log(newRoles);
      setUserRolesList(newRoles);

  }

  let userRolesTableItems = [];
  if (userView != null){
      //console.log("contact_methods", contact_methods);
      userRolesTableItems = userView.userInfo.roles.map((role, index) => {
        let valueText = role.names[0];
        //console.log("locale",userLocale);
        role.names.forEach((item, i) => {
            if (item.lang == userLocale){
              valueText = item.value;
            }
        });
  
        let hasDelete = role.role_id != 4;
       
        return <tr  hover key={`${index}_${role._id}`}>
            <th>{valueText}</th>
            {hasDelete ? <th className="pointer"  onClick={(e) => { onOpenDeleteUserRole(e,role)}}><button className="btn btn-primary">{trans.delete_btn}</button></th> : null }
          </tr>
      });
   }

   const setUserRolesSelection = () => {
    if (userView != null){
      //Check if the user hasnt already have the role to add it as posible selection
      var items = [];
        settingsData.userRoles.forEach(role => {
          var exixts = false
          userView.userInfo.roles.map((userRole, index) => {
            if (userRole.slug == role.slug){
              exixts = true;
            }
          });
          if (!exixts){
            items.push(role);
          }
        });
        setUserRolesSelectionItems(items);

        //if (userRoleValue == null || userRoleValue == "") {
          if (items.length > 0){
            setUserRoleValue(items[0]._id);
          }
         
        //}
    }
   }


 

  const addCustomSubItem = (e) => {
      e.preventDefault();
      let userId = userView.userInfo.userId;
      let postData  = {userId: userId,trainingId: fromAdminTrainingId,expireDate: fromAdminExpireDate}

      dispatch(createCustomSub(postData));
  }

  const onDeleteCustomSub = (e,item) => {
    e.preventDefault();
    //TODO
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
          //REMOVE
          let postData = {membershipId: item._id}
          dispatch(deleteCustomSub(postData));
      } else {
        
      }
    });
  }

  function renderCustomSubs() {
    return(   <Card>
      <CardHeader>
              <h4 className="card-title mb-0">{trans.custom_subs_title}</h4>
              <div className="card-options">
                <a className="card-options-collapse" href="#javascript">
                  <i className="fe fe-chevron-up"></i>
                </a>
                <a className="card-options-remove" href="#javascript">
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </CardHeader>
            <CardBody> 
            {trainingsData != null && customSubsData != null ?  
                 <Form className="row" noValidate autoComplete="off">
                  <Col sm="3" md="3" xl="4">
                    <Label className="form-label">{trans.training_name}</Label>
                    <Input
                      id='fromAdminTrainingId'
                      type="select" name="select"
                      value={fromAdminTrainingId}
                      onChange={handleFromAdminTrainingId}
                      margin="normal"
                      fullWidth>
                      {trainingsData.trainings.map((item) => (
                        <option key={item.id} value={item.id}>{item.title}</option>
                      ))}
                    </Input>
                    </Col>
                 
                
                  <Col sm="3" md="3" xl="4">
                    <FormGroup >
                      <Label className="form-label">{trans.custom_subs_expire_date}</Label>
                      <DatePicker  placeholder={trans.custom_subs_expire_date} className="form-control digits" dateFormat="dd/MM/yyyy" selected={fromAdminExpireDate} onChange={(date) => {setFromAdminExpireDate(date)}} />
                    </FormGroup>
                  </Col>

                  <Col sm="2" md="2" xl="3">
                    <button
                    disabled={fromAdminExpireDate == "" || fromAdminExpireDate == ""}
                    className="add_contact_method_button btn btn-primary"
                        onClick={(e) => {addCustomSubItem(e)}}>
                     {trans.add_btn}
                    </button>
                  </Col>
                  <Col md="12">
                  <br/>
                  {customSubsData.customSubs.length > 0 ? (
                 <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                        <tr>
                          <th>{trans.training_name}</th>
                          <th>{trans.custom_subs_expire_date}</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                    <tbody>
                      {customSubsData.customSubs.map((detail, index) => {
                        return <tr  hover key={index}>
                            <th>{detail.fromAdminTrainingId.title} </th>
                            <th><Moment
                                locale={userLocale}
                                format={DATE_TIME_FORMAT}
                              >
                                {detail.nextDate}
                              </Moment></th>
                              
                               <th className="pointer"  onClick={(e) => { onDeleteCustomSub(e,detail)}}><button className="btn btn-secondary">{trans.delete_btn}</button></th>
                          </tr>
                      })}
                    </tbody>
                  </table>) : (<div className="justify-content-center text-center m-auto">
                    <br/>
                    <span >{trans.no_contact_methods}</span>
                    </div>)
                  }
                </Col>
                </Form>

          : <LoaderBox/>}
                </CardBody>
                </Card>)
  }


  return (
    <Fragment>
      <Breadcrumb parent={trans.menu_users} title={trans.edit_profile_title} />
      <Container fluid={true}>
        {!canEditUser ? <NoAccess/> :
        userView == null ?  <Loader/> :
        <div className="edit-profile">
          <Row>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{trans.profile_title}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <div className="col-auto">
                        <img className="rounded-circle shadow-sm" style={{objectFit: "cover",width:70, height: 70}} alt="" src={userView.userInfo.img_thumb} />
                      </div>
                      <Col>
                        <h3 className="mb-1">{userView.userInfo.firstName}</h3>
                        <p className="mb-1">{userView.userInfo.lastName}</p>
                        <p className="mb-4">{userView.userInfo.age} {trans.user_age}</p>
                      </Col>
                    </Row>
                    {/* <FormGroup>
                      <h6 className="form-label">{Bio}</h6>
                      <Input type="textarea" className="form-control" rows="5" defaultValue="On the other hand, we denounce with righteous indignation" />
                    </FormGroup> */}
                    <FormGroup>
                      <Label className="form-label">{trans.email_field}</Label>
                      <Input readOnly className="form-control" value={userView.userInfo.email} />
                    </FormGroup>
                    {/* <FormGroup>
                      <Label className="form-label">{Password}</Label>
                      <Input className="form-control" type="password" defaultValue="password" />
                    </FormGroup> */}
                    <FormGroup>
                      <Label className="form-label">{trans.nickname_field}</Label>
                      <Input readOnly className="form-control" value={userView.userInfo.nickname} />
                    </FormGroup>
                    <div className="form-footer">
                     <button onClick={(e) => {sendForgotPassword(e)}} className="btn btn-secondary btn-block">{trans.web_change_password_title}</button>
                     <br/>
                      <Link to={`/app/users/userProfile/${userView.userInfo.nickname}`}>
                      <button className="btn btn-primary btn-block">{trans.web_view_profile}</button>
                      </Link>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              {!isAdmin ? null : <Card>
               <CardHeader>
                  <h4 className="card-title mb-0">{trans.user_roles_field}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                <Form className="row" noValidate autoComplete="off">
                      <Col sm="7" md="7" xl="7">
                        <Label className="form-label">{trans.user_role_single}</Label>
                        <Input
                          id='userRoleValue'
                          type="select" name="select"
                          value={userRoleValue}
                          onChange={(e) => handleUserRoleValue(e)}
                          margin="normal"
                          fullWidth>
                          {userRolesSelectionItems.map((option) => {
                            var nameText = option.names[0];
                            option.names.map((item) => {
                                if (item.lang == userLocale){
                                  nameText = item.value;
                                }
                            });
                            return (<option key={option._id} value={option._id}>
                              {nameText}
                            </option>)
                          })}
                        </Input>
                        </Col>
                      <Col sm="5" md="5" xl="5">
                        <button
                        disabled={userRoleValue == "" || userRolesSelectionItems.length == 0}
                        className="add_contact_method_button btn btn-primary"
                            onClick={(e) => {addUserRoleItem(e)}}>
                         {trans.add_btn}
                        </button>
                      </Col>
                      <Col md="12">
                      <br/>
                      {userView.userInfo.roles.length > 0 ? (
                     <table className="table card-table table-vcenter text-nowrap">
                        <thead>
                            <tr>
                              <th>{trans.user_role_single}</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                        <tbody>
                          {userRolesTableItems}
                        </tbody>
                      </table>) : (<div className="justify-content-center text-center m-auto">
                        <br/>
                        <span >N/A</span>
                        </div>)
                      }
                    </Col>
                    </Form>
                </CardBody>
              </Card> }
              {isAdmin ? <UserStatus userInfo={userView.userInfo}/> : null}
            </Col>
            <Col xl="8">
              <Col xl="12">
              <Form className="card">
                <CardHeader>
                  <h4 className="card-title mb-0">{trans.edit_profile_title}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <Label className="form-label">{trans.first_name_field}</Label>
                        <Input className="form-control" type="text" placeholder={trans.first_name_field} value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}/>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <FormGroup>
                        <Label className="form-label">{trans.last_name_field}</Label>
                        <Input className="form-control" type="text" placeholder={trans.last_name_field} value={lastName} onChange={(e)=>{setLastName(e.target.value)}}/>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <FormGroup>
                        <Label className="form-label">{trans.nickname_field}</Label>
                        <Input readOnly className="form-control" type="text" placeholder={trans.nickname_field} defaultValue={userView.userInfo.nickname}/>
                      </FormGroup>
                    </Col>
                    <Col sm="4" md="4">
                      <FormGroup>
                        <Label className="form-label">{trans.email_field}</Label>
                        <Input  readOnly className="form-control" type="email" placeholder={trans.email_field} defaultValue={userView.userInfo.email}/>
                      </FormGroup>
                    </Col>
                    <Col sm="4" md="4">
                      <FormGroup>
                        <Label className="form-label">{trans.height_field}</Label>
                        <Input  className="form-control" type="number" placeholder={trans.height_field} defaultValue={userView.userInfo.height} onChange={(e)=>{setHeight(e.target.value)}}/>
                      </FormGroup>
                    </Col>
                    <Col sm="4" md="4">
                      <FormGroup>
                        <Label className="form-label">{trans.weight_field}</Label>
                        <Input  className="form-control" type="number" placeholder={trans.weight_field} defaultValue={userView.userInfo.weight} onChange={(e)=>{setWeight(e.target.value)}}/>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <FormGroup>
                         <Label className="form-label">{trans.date_birth_field}</Label>
                         <DatePicker  selected={birthDate} placeholder={trans.date_birth_field} className="form-control digits" dateFormat="dd/MM/yyyy" selected={birthDate} onChange={handleBirthDateChange} />
                      </FormGroup>
                    </Col>
                    {/* <Col md="12">
                      <FormGroup>
                        <Label className="form-label">{Address}</Label>
                        <Input className="form-control" type="text" placeholder="Home Address" />
                      </FormGroup>
                    </Col> */}
                    {/* <Col sm="6" md="4">
                      <FormGroup>
                        <Label className="form-label">{City}</Label>
                        <Input className="form-control" type="text" placeholder="City" />
                      </FormGroup>
                    </Col> */}
                    {/* <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{PostalCode}</Label>
                        <Input className="form-control" type="number" placeholder="ZIP Code" />
                      </FormGroup>
                    </Col> */}
                    <Col md="4">
                      <FormGroup>
                        <Label className="form-label">{trans.gender_select}</Label>
                        <Input type="select" name="select" className="form-control btn-square" value={gender} onChange={(e)=>{setGender(e.target.value)}}>
                        <option key={"gender_select"} value={0} defaultValue={0}>{trans.gender_select}</option>
                         <option  key={"gender_male"} value={1}>{trans.gender_male}</option>
                         <option key={"gender_female"} value={2}>{trans.gender_female}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="form-label">{trans.nationality_hint}</Label>
                        <Input type="select" name="select" className="form-control btn-square" value={nation} onChange={(e)=>{setNation(e.target.value)}}>
                          <option key={"nation_select"} value={""} defaultValue={""}>{trans.nationality_hint}</option>
                          {settingsData.countries.map((items,i) => 
                            <option key={i} data-icon={items.icon} value={items._id}>  {items.name}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <div className="form-group mb-0">
                        <Label className="form-label">{trans.user_bio_field}</Label>
                        <Input value={bio} type="textarea" className="form-control" rows="5" placeholder={trans.user_bio_field}  onChange={(e)=>{setBio(e.target.value)}}/>
                      </div>
                    </Col>
                  
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <button onClick={(e)=> {updateUserProfile(e)}} className="btn btn-primary" type="submit">{trans.edit_btn}</button>
                </CardFooter>
              </Form>
              </Col>
              <Col xl="12">
          <Card>
          <CardHeader>
                  <h4 className="card-title mb-0">{trans.contact_methods_title}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody> 
                     <Form className="row" noValidate autoComplete="off">
                      <Col sm="3" md="3" xl="4">
                        <Label className="form-label">{trans.contact_method_type}</Label>
                        <Input
                          id='contact_method_type'
                          type="select" name="select"
                          value={contact_method_type}
                          onChange={handleContactMethodType}
                          margin="normal"
                          fullWidth>
                          {settingsData.contactMethods.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Input>
                        </Col>
                     
                    
                      <Col sm="3" md="3" xl="4">
                        <Label className="form-label">{trans.contact_method_value}</Label>
                        <Input
                          id="contact_method_value"
                          value={contact_method_value}
                          onChange={(e)=>{setContactMethodValue(e.target.value)}}
                          margin="normal"
                          fullWidth
                        />
                      </Col>

                      <Col sm="2" md="2" xl="3">
                        <button
                        disabled={contact_method_value == "" || contact_method_type == ""}
                        className="add_contact_method_button btn btn-primary"
                            onClick={(e) => {addContactMethodItem(e)}}>
                         {trans.add_btn}
                        </button>
                      </Col>
                      <Col md="12">
                      <br/>
                      {userView.userInfo.contact_methods.length > 0 ? (
                     <table className="table card-table table-vcenter text-nowrap">
                        <thead>
                            <tr>
                              <th>{trans.contact_method_type}</th>
                              <th>{trans.contact_method_value}</th>
                              <th></th>
                            </tr>
                          </thead>
                        <tbody>
                          {contactMethodsTableItems}
                        </tbody>
                      </table>) : (<div className="justify-content-center text-center m-auto">
                        <br/>
                        <span >{trans.no_contact_methods}</span>
                        </div>)
                      }
                    </Col>
                    </Form>
                    </CardBody>
                    </Card>
                    </Col>
                    
                    {isAdmin ? 
                    <Col>
                      {renderCustomSubs()}
                    </Col>
                    : null}
                   
            </Col>
            
          </Row>

          <Row>
         
          
              
           
          </Row>

        </div>}
      </Container>
    </Fragment>
  );
}

export default UserEdit;