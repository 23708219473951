import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SIGNOUT_USER_SUCCESS,
    USER_DATA,
    USER_TOKEN_SET,
    USER_ID_SET,
    SHOW_MESSAGE,
    FETCH_DATA,
    CREATE_USER_SUCCESS,
    RESET_PASS,
    FORGOT_PASS,
    USER_INFO_STORED,
    EDIT_USER,
    SIGN_OUT,
    SIGN_IN,
    UPDATE_USER_ROLES,
    UPLOAD_PROFILE_IMAGE,
    CHANGE_STATUS,
    USER_IS_ADMIN,
    UPDATE_LAST_ONLINE,
    USER_IS_MANAGER,
    USER_IS_COACH,
    USER_NOTIFICATION_SETTINGS,
    USER_CHANGE_NOTIFICATION_SETTING,
    EDIT_USER_SELECTED_TRACK
  } from '../actionTypes';
  import axios from '../../util/Api'
  
  import {GENERAL_ERROR} from '../../constant';
  import { subscribeToTopic, logEvent, unsubscribeToTopic } from '../../firebase/FirebaseInit';
  import * as Const from '../../constant';
  import * as General from '../../util/General'
  
  
  export const setInitUrl = (url) => {
    return {
      type: INIT_URL,
      payload: url
    };
  };
  
  //TODO DELETE THIS
  export const userSignUp = (postData) => {
    const userLocale = General.getLocale();
    postData.langLoc =  userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('account/register', postData).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: CREATE_USER_SUCCESS, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const userSignIn = (email, password) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
  
      let brand = navigator.platform;
      let model = navigator.userAgent;
      let type = "web";
      var navigator_info = window.navigator;
      var screen_info = window.screen;
      var uid = navigator_info.mimeTypes.length;
      uid += navigator_info.userAgent.replace(/\D+/g, '');
      uid += navigator_info.plugins.length;
      uid += screen_info.height || '';
      uid += screen_info.width || '';
      uid += screen_info.pixelDepth || '';
      let deviceId = uid;

      const userLocale = General.getLocale();
      
      let postData = {
        email: email,
          password: password,
          langLoc:userLocale,
          brand: brand,
          model: model,
          type: type,
          deviceId: deviceId,
        fromAdmin: true
      };

      axios.post('account/login', postData
      ).then(({data}) => {
        console.log("userSignIn: ", data);
        if (data.status){
          //SET BEARER
          axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;

          //STORE DATA TO REMAIN LOGGED IN
          localStorage.setItem(USER_TOKEN_SET, JSON.stringify(data.token));
          localStorage.setItem(USER_ID_SET, JSON.stringify(data.userId));

          //SUBSCRIBE TO FIREBASE TO RECEIVE NOTIFICATIONS
          let topic = `${Const.ARG_FIREBASE_TOPIC_USER}${data.userId}`;
          subscribeToTopic(topic);

          let topicAll = `${Const.ARG_FIREBASE_TOPIC_ALL}`;
          subscribeToTopic(topicAll);
      
          //RETURN DATA
          dispatch({type: FETCH_SUCCESS});
           dispatch({type: SIGN_IN, payload: data});
        } else {
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        console.log(error);
        dispatch({type: SHOW_MESSAGE, payload: GENERAL_ERROR});
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  export  const  getUser =  (userId)  =>  {
    const userLocale = General.getLocale();

    let postData = {
      userId: userId,
        langLoc:userLocale,
      fromAdmin: true
    };
    
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/userinfo', 
      postData
      ).then(({data}) => {
        if (data.userInfo){
          if (data.userInfo.userId === userId){ //STORE THE LOGGED IN USER DATA
         //   localStorage.setItem(USER_INFO_STORED, JSON.stringify(data.userInfo));
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: USER_DATA, payload: data.userInfo});
            var isAdmin = false;
            if (data.userInfo != null){
              data.userInfo.roles.forEach(userRole => {
                if (userRole.role_id == 1){
                  isAdmin = true;
                }
              });
            }

            var isManager = false;
            if (data.userInfo != null){
              data.userInfo.roles.forEach(userRole => {
                if (userRole.role_id == 2){
                  isManager = true;
                }
              });
            }

            var isCoach = false;
            if (data.userInfo != null){
              data.userInfo.roles.forEach(userRole => {
                if (userRole.role_id == 3){
                  isCoach = true;
                }
              });
            }

            dispatch({type: USER_IS_ADMIN, payload: isAdmin});
            dispatch({type: USER_IS_MANAGER, payload: isManager});
            dispatch({type: USER_IS_COACH, payload: isCoach});
          } else {
            dispatch({type: USER_DATA, payload: data.userInfo});
          }
        } else {
          dispatch({type: USER_DATA, payload: data});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  
  export const userSignOut = (postData) => {
    const userLocale = General.getLocale();
    postData.langLoc = userLocale;
    let model = navigator.userAgent;
    postData.model = model;

    console.log(postData);

    return (dispatch) => {

      axios.post('account/logout',
      postData
      ).then(({data}) => {
        console.log(data);
        if (data) {

          const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
          let topic = `${Const.ARG_FIREBASE_TOPIC_USER}${userId}`;
          unsubscribeToTopic(topic);

          let topicAll = `${Const.ARG_FIREBASE_TOPIC_ALL}`;
          unsubscribeToTopic(topicAll);

          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_SUCCESS});
          localStorage.removeItem(USER_TOKEN_SET);
          localStorage.removeItem(USER_ID_SET);
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SIGN_OUT, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const forgotPass = (postData) => {
    const userLocale = General.getLocale();
    postData.langLoc =  userLocale;
    return (dispatch) => {
      axios.post('account/forgot', postData).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FORGOT_PASS, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const resetPass = (postData) => {
    const userLocale = General.getLocale();
    postData.langLoc =  userLocale;
    return (dispatch) => {
      axios.post('account/resetpass', postData).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: RESET_PASS, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };


  export const editUser = (postData) => {
    const userLocale = General.getLocale();
    postData.langLoc =  userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('account/edit', postData).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: EDIT_USER, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  


  export const editRoles = (postData) => {
    const userLocale = General.getLocale();
    postData.langLoc =  userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('account/updateUserRoles', postData).then(({data}) => {
        //console.log(data);
        if (data){
          dispatch({type: UPDATE_USER_ROLES, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  

  export const postUploadUserImage = (userId,file) => {
    //Create a form data object;
    var formData = new FormData();
    formData.append("userId", userId);
    formData.append('upload', file);

    return (dispatch) => {
      axios.post('account/uploadimage', formData).then(({data}) => {
       // console.log(data);
        if (data){
          dispatch({type: UPLOAD_PROFILE_IMAGE, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };



  export const changeAccountStatus = (postData) => {
    //Get user locale of the machine he is on
   
    const userLocale = General.getLocale();

  
    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/status', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: CHANGE_STATUS, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  
  


  export const updateLastOline = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/updateLastOnline', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_LAST_ONLINE, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  

  export const postNotificationSettings = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/notificationsetting', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: USER_NOTIFICATION_SETTINGS, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };


  export const postChangeNotificationSettings = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/changenotifsetting', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: USER_CHANGE_NOTIFICATION_SETTING, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const postEditTrack = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('account/edit-track', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: EDIT_USER_SELECTED_TRACK, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  
  