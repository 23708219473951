import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'

import Todoapp from './todo/reducer'
import Ecommerce from './ecommerce/product/reducer'
import Filters from './ecommerce/filter/reducer'
import Wishlist from './ecommerce/wishlist/reducer'
import Cart from './ecommerce/cart/reducer'
import ChatApp from './chap-app/reducer'
import EmailApp from './email/reducer'
import Customizer from './customizer/reducer'
import Bookmarkapp from './bookmark/reducer'
import Taskapp from './task-app/reducer'
import Projectapp from './project-app/reducer'

//NEW ADDED
import Auth from './auth/reducer';
import Settings from './settings/reducer';
import Translation from './translation/reducer';
import User from './user/reducer';
import Feedback from './feedback/reducer';
import UserMedia from './usermedia/reducer';
import Upload from './upload/reducer';
import ScoreTypes from './scoretypes/reducer';
import Trainings from './trainings/reducer';
import Tracks from './tracks/reducer';
import Workouts from './workouts/reducer';
import Schedules from './schedule/reducer';
import DoneSchedules from './doneschedule/reducer';
import Subscription from './subscription/reducer';
import  Chat  from  './chat/reducer';
import  Blog  from  './blog/reducer';
import  Prworkout  from  './prworkout/reducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    Todoapp,
    data:Ecommerce,
    filters:Filters,
    Wishlistdata:Wishlist,
    Cartdata:Cart,
    ChatApp,
    EmailApp,
    Customizer,
    Bookmarkapp,
    Taskapp,
    Projectapp,
    auth:Auth,
    translation: Translation,
    settings: Settings,
    user: User,
    feedback: Feedback,
    usermedia: UserMedia,
    upload: Upload,
    scoreTypes: ScoreTypes,
    trainings: Trainings,
    tracks: Tracks,
    workouts: Workouts,
    schedules: Schedules,
    doneSchedules: DoneSchedules,
    subscription: Subscription,
    chat: Chat,
    blog: Blog,
    prworkout: Prworkout
});
