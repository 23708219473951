import './users.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { CHAT_CREATE_ROOM, STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { getAdminUsers, getCoachesData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import NoAccess from '../../components/reuse/noaccess';
import LoaderBox from '../../components/reuse/loaderbox';
import { createChatRoom } from '../../redux/chat/action';
import ChatModal from '../chat/chatModal';


const CoachesCards = (props) => {
  
  const userLocale = General.getLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector(({ auth }) => auth.authUser);
  const coachesData = useSelector(({user}) => user.coachesData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const createChatRoomData = useSelector(({chat}) => chat.createChatRoomData);  

  const [cards,setCards] = useState([])
  const trans = useSelector(({translation}) => translation.translationsData);
  const [searchText, setSearchText] = useState('');
 
  var searchKeyword = "";

  const [curPage, setCurPage] = useState(0);

  const [seletedChatRoomId,setSeletedChatRoomId] = useState(null);
  const [selectedChatUserId,setSelectedChatUserId] = useState(null);


  const [chatModalStatus,setChatModalStatus] = useState(false);
  

  useEffect(() => {
    if (createChatRoomData != null){
      //NOW THAT WE HAVE THE ROOM WE NEED TO FETCH THE MESSAGES THAT ARE ASSOCIATED WITH THIS ROOM ID
      setSeletedChatRoomId(createChatRoomData.room_id);
      dispatch({type: CHAT_CREATE_ROOM, payload: null});
    }
  }, [createChatRoomData]);

  

  useEffect(() => {
    if (seletedChatRoomId != null){
      // history.push(`/app/chat/${seletedChatRoomId}`);
    }
  }, [seletedChatRoomId]);

  useEffect(() => {
    getUsersData();
}, []);

  const getUsersData = () => {
    let postData = {page: curPage+1,sort: {"_id": -1},query: {},searchKeyword: searchKeyword}
    dispatch(getCoachesData(postData));
  }

  useEffect(() => {
    getUsersData();
  }, [curPage]);

  const addEditUserModaltoggle = () => {

  }

  const handleSearchKeyword = async (keyword)   => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getUsersData();
  }

  const handleUserEditClick = (e,user) => {
    e.preventDefault();
    history.push(`/app/users/userEdit/${user.nickname}`);
  }

  const removeUser = (user)=> {

  }

  const handlePageClick = (e,newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  }

  const openProfile = (e,user) => {
    e.preventDefault();
    history.push(`/app/users/userProfile/${user.nickname}`);
  }

  const openChat  = (e,user) => {
    e.preventDefault();
    setSelectedChatUserId(user.userId)
    setChatModalStatus(true);
  }




  const onChatModalClose = () => {
    setChatModalStatus(false);
    setSeletedChatRoomId("");
    setSelectedChatUserId("");
  }

  return (
    <Fragment>
      <Breadcrumb parent={trans.menu_coaches} title={trans.menu_coaches} />
      <Container fluid={true}>
        {coachesData != null ? 
        <Row>
            {coachesData.coaches.map((coach, i) => 
            <Col md="6" lg="6" xl="4" className="box-col-6" key={i}>
              <Card className="custom-card">
                <CardHeader>
                  <Media body className="img-fluid" src={coach.banner_img} alt="" />
                </CardHeader>
                <div className="card-profile">
                  <img   className="rounded-circle shadow-sm" style={{objectFit: "cover",width:'150px',height:'150px'}} src={coach.img_thumb} alt="" />
                </div>
                <span style={{textAlign:'center',color:'#bcc6de',padding:'13px',transition:'all 0.3s ease',fontSize:'12px'}}>{coach.roleName}</span>
                {/* <ul className="card-social">
                  <li><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                  <li><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                  <li><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                  <li><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                  <li><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                </ul> */}
                <div className="text-center profile-details">
                  <h4>{coach.fullName}</h4>
                  <h6 style={{color:'#f50e02'}}>@{coach.nickname}</h6>
                </div>
                <CardFooter className="row">
                  <Col sm="6 col-6">
                    <button onClick={(e)=> openProfile(e,coach)} className="btn btn-primary">{trans.web_view_profile}</button>
                    {/* <h6>{Follower}</h6>
                    <h3 className="counter">{cardItem.follower}</h3> */}
                  </Col>
                  <Col sm="6 col-6">
                   <button  onClick={(e)=> openChat(e,coach)} className="btn btn-secondary">{trans.chat_with_user}</button>
                    {/* <h6>{Following}</h6>
                    <h3><span className="counter">{cardItem.following}</span>{"K"}</h3> */}
                  </Col>
                </CardFooter>
              </Card>
            </Col>
            )}
          </Row> : <LoaderBox/>}

          <ChatModal
            open={chatModalStatus}
            chatRoomPassedId={seletedChatRoomId}
            selectedUser={null}
            chatRoomUserId={selectedChatUserId}
            onChatModalClose={onChatModalClose}
          />
      </Container>
    </Fragment>
  );
}

export default CoachesCards;