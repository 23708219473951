import './athletesLab.css';
import React, { Fragment,useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button , Pagination,
  PaginationItem,
  PaginationLink  } from 'reactstrap'
  import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { DATE_TIME_FORMAT,MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PaddingRight} from '../../constant'
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';
import { getUserData } from '../../redux/user/action';
import { Loader } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { editUser, forgotPass, getUser,editRoles, changeAccountStatus } from '../../redux/auth/action';

import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import * as General from '../../util/General'
import SweetAlert from 'sweetalert2'
import { checkUserSub } from '../../redux/subscription/action';
import LoaderBox from '../../components/reuse/loaderbox';
import { fetchAthletesLab } from '../../redux/blog/action';
import Moment from "react-moment";

const AthletesLab = (props) => {

  const history = useHistory();
  const userLocale = General.getLocale();
    const dispatch = useDispatch();

    const trans = useSelector(({translation}) => translation.translationsData);
    const athletesLabData = useSelector(({blog}) => blog.athletesLabData);

    const checkSubData = useSelector(({subscription}) => subscription.checkSubData);

    const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
    const userIsManager = useSelector(({auth}) => auth.userIsManager);
    const userIsCoach = useSelector(({auth}) => auth.userIsCoach);

    const [blogList, setBlogList] = useState([]);
    const [curPage, setCurPage] = useState(1);

    var selectedType = "TRAINING_RESOURCES";
    let { id } = useParams();
    if (id != null){
      selectedType = id;
    }


    useEffect(() => {
     
      fetchBlogs();
    }, [selectedType]);


    useEffect(() => {
      fetchBlogs();
      getUserSubs();
    }, []);

    const getUserSubs = () => {
      let postData = {}
      dispatch(checkUserSub(postData));
    }

  const fetchBlogs = () => {
    let postData = {
        "page": curPage,
        "type": selectedType
    };
    dispatch(fetchAthletesLab(postData));
   }


     
   useEffect(() => {
    if (athletesLabData != null && checkSubData != null){
        var ar = [];
        athletesLabData.categories.forEach(element => {
          if (userIsAdmin || userIsManager || userIsCoach){
            ar.push(element);
          } else {
            checkSubData.subscriptions.forEach(sub => {
              element.plan_id.forEach(elementPlan => {
                if (sub.planId.includes(elementPlan)){
                  ar.push(element);
                }
              });
            });
          }
        });
        setBlogList(ar);
    }
  }, [athletesLabData,checkSubData]);


  const handlePageClick = (e, newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  };

  const openArticle = (e,item) => {
    e.preventDefault();
    if (item.hasMore){
      history.push(`/app/athleteslab/${item.slug}`);
    }else {
      history.push(`/app/blog-single/${item.articleId}`);
    }
    
  }

    return (
      <Fragment>
      <Breadcrumb parent={trans.bottom_nav_athletes_lab} title={trans.bottom_nav_athletes_lab} />
      <Container fluid={true}>
        <Card>

        <CardBody>
        
        {athletesLabData != null && blogList.length > 0 ? 

          <div>
              <Row>
                  {blogList.map((item,index)=> {
                   
                    return(<Col md="3">
                        <div style={{boxShadow: '0 0 20px rgb(8 21 66 / 5%)',margin:'10px'} }>

                               {item.image == null || item.image == "#" || item.image == "" ? null : 
                               <div style={{marginTop: '10px',width: '100%',height:'200px'}}>
                                <img style={{height:'100%', width:'100%', objectFit:'cover'}}  src={item.image}/>
                              </div>
                              } 
                              <br />
                              <div style={{paddingLeft:'10px'}}>
                             

                                <div>
                                  <br/>
                                  <h5 className="font-weight-bold">{item.title}</h5> 
                                </div>

                                <br />
                                <div style={{float:'right',paddingRight:'5px'}}>
                                  <button style={{color:'red'}} onClick={(e)=> {openArticle(e,item)}} className="btn btn-none" type="submit">{trans.blog_view_more}</button>
                                </div>
                                 
                                <br />
                                <br />
                              </div>
                             

                        </div>
                    </Col>)
                  })}
              </Row>
          </div>
          
          : <LoaderBox/>}

        </CardBody>

        {/* <CardFooter>

            {athletesLabData != null ? 
            <div style={{ float: "right" }}>
                    <Pagination aria-label="" className="pagination-secondary">
                      <PaginationItem disabled={curPage <= 0}>
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage - 1)}
                          previous
                          href="#"
                        />
                      </PaginationItem>

                      {[...Array(athletesLabData.pages)].map((page, i) => (
                        <PaginationItem active={i === curPage} key={i}>
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i)}
                            href="#"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem
                        disabled={curPage >= athletesLabData.pages - 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, curPage + 1)}
                          next
                          href="#"
                        />
                      </PaginationItem>
                    </Pagination>
                  </div> : <div></div> }
            </CardFooter> */}
        </Card>
       
      </Container>
    </Fragment>
  );
}

export default AthletesLab;