import {
  FETCH_SCHEDULES,
  ADD_SCHEDULE,
  EDIT_SCHEDULE,
  DELETE_SCHEDULE,
  SINGLE_SCHEDULE,
  EDIT_SCHEDULE_WORKOUT,
USER_ID_SET,
CLONE_WEEK
  } from '../actionTypes';
  import axios from '../../util/Api'
  import * as General from '../../util/General'
  
  import {GENERAL_ERROR} from '../../constant';
  
  

  export const getSchedules = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log("getSchedules",postData);
    return (dispatch) => {
      axios.post('schedule/all', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: FETCH_SCHEDULES, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const addSchedule = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.creator = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('schedule/add', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: ADD_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const editSchedule = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('schedule/editOrder', postData
      ).then(({data}) => {
     //   console.log(data);
        if (data){
          dispatch({type: EDIT_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };

  export const singleSchedule = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    //console.log(postData);
    return (dispatch) => {
      axios.post('schedule/single', postData
      ).then(({data}) => {
        // console.log(data);
        if (data){
          dispatch({type: SINGLE_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const deleteScheduleWorkout = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
   // console.log(postData);
    return (dispatch) => {
      axios.post('schedule/deleteWorkout', postData
      ).then(({data}) => {
      //   console.log(data);
        if (data){
          dispatch({type: DELETE_SCHEDULE, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const editScheduleWorkout = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('schedule/editWorkout', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: EDIT_SCHEDULE_WORKOUT, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };


  export const cloneWeek = (postData) => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userLocale = General.getLocale();

    postData.userId = userId;
    postData.langLoc = userLocale;
    console.log(postData);
    return (dispatch) => {
      axios.post('schedule/cloneweek', postData
      ).then(({data}) => {
         console.log(data);
        if (data){
          dispatch({type: CLONE_WEEK, payload: data});
        }
      }).catch(function (error) {
        console.log("Error****:", error.message);
      });
    }
  };